import axios from 'axios';
import {API} from '../axios-config';
import FetchingData from "@/otherJS/FetchingData";
import {includeFilteringByTag, includeFilteringByType} from "@/otherJS/include-filtering";
import {BACKEND_URL} from "@/config";
import {getCodeLife} from "@/otherJS/getCodeLife";
import generateErrorText from "@/otherJS/generateErrorText";
import {ActiveEcho} from "@/main";
import {createErrorByResponse, createErrorByType} from "@/otherJS/createErrorTextByType";
import staticData from "@/Vuex/modules/staticData";


const DATE_NOW = Math.round(Date.now() / 1000); // текущее значение даты в секундах

export default {
    state: {
        client_secret: '',
        client_id: '',
        urlToRegistrationCode: null,
        canUDoReqWithUser: false,

        auth: {
            isAuthOpen: false,
            isAuthenticate: false,
            prevRegModal: 'main',
            activeRegModal: 'main',
            authType: '',
            isRequest: false,
            errorText: '',
            regCodeLife: '',
        },
        user: {
            attributes: {},
            compositionsForDisciplines: []
        },
        userTeams: [],
        userManagedTeams: [],
        userCaptainTeams: [],
        userRequestedTeams: [],
        usersFromIds: [],
        anotherUser: {attributes: {}},
        anotherUserTeams: [],
        anotherUserManagedTeams: [],
        isUserRequestToTeam: false,
        isUserHaveEntriesTeamToUser: '',
        entryDataForTeamToUser: null,
        otherUserIsHaveEntriesTeamToUser: false

    },
    mutations: {
        SET_IS_REQS_WITH_USER: (state, data) => {
            state.canUDoReqWithUser = data;
        },

        SET_SECRET: (state, data) => {
            state.client_secret = data.secret;
            state.client_id = data.id;
        },

        SET_USER_TO_STATE: (state, user) => {
            state.user = user.data;
            state.userTeams = user.teams;
            state.userManagedTeams = user.managedTeams;
            state.user.compositionsForDisciplines = user.compositionsForDisciplines;
        },
        SET_ANOTHER_USER_TO_STATE: (state, data) => {
            state.anotherUser = data.data;
            state.anotherUserTeams = data.teams;
            state.anotherUserManagedTeams = data.managedTeams;
        },

        SET_USER_EMAIL: (state, email) => {
            state.user.attributes.email = email;
        },
        SET_USER_PHONE: (state, phone) => {
            state.user.attributes.phone = phone;
        },
        SET_URL_TO_REG_CODE: (state, url) => {
            state.urlToRegistrationCode = url;
        },
        SET_USER_AVATAR: (state, avatar) => {
            state.user.logo = avatar;
        },
        SET_USER_BACKGROUND: (state, background) => {
            state.user.background = background;
            sessionStorage.setItem('user', JSON.stringify(state.user))
        },
        SET_USER_TOKEN: (state, data = {}) => {
            state.user.accessToken = data.access;
            localStorage.setItem('userAccessToken', data.access);
            if (data.refresh !== null) {
                localStorage.setItem('refreshAccessToken', data.refresh);
                let expiresDate = DATE_NOW + (data.expires_in - 900); //получаем дату, когда сдохнет токен минус 15 минут
                localStorage.setItem('expiresTokenDate', expiresDate);
            }
        },
        SET_AUTH: (state, auth) => {
            state.auth.isAuthenticate = auth;
        },
        SET_IS_AUTH: (state, isAuth) => {
            state.auth.isAuthOpen = isAuth;
        },
        SET_AUTH_TYPE: (state, authType) => {
            state.auth.authType = authType;
        },
        SET_REG_MODALS: (state, activeModal) => {
            state.auth.prevRegModal = state.auth.activeRegModal;
            state.auth.activeRegModal = activeModal;
            state.auth.errorText = '';
        },
        SET_IS_REQUEST: (state, isRequest) => {
            // console.log('request', isRequest)
            state.auth.isRequest = isRequest;
        },
        SET_ERROR: (state, error) => {
            state.auth.errorText = error;
        },
        UPDATE_USER_DATA: (state, data) => {
            state.user.attributes = data;
        },
        UPDATE_USER_ATTRIBUTES: (state, attrs) => {
            state.user.attributes = attrs;
        },

        SET_USER_REQUEST_TO_TEAM: (state, data) => {
            state.isUserRequestToTeam = data;
        },
        SET_USER_REQUESTED_TEAMS: (state, data) => {
            state.userRequestedTeams = data;
        },

        SET_REG_CODE_LIFE: (state, data) => {
            state.auth.regCodeLife = data;
        },

        SET_USER_CAPTAIN_TEAMS(state, data) {
            state.userCaptainTeams = data;
        },

        // mutation, который смотрит есть ли у пользователя заявка от определенной команды
        // или нет
        SET_IS_HAVE_ENTRIES_TEAM_TO_USER(state, payload) {
            state.isUserHaveEntriesTeamToUser = payload
        },

        // mutation, который запоминает, какие заявки с командой были получены
        // с типом TeamToUser
        SET_ENTRY_DATA_FOR_TEAM_TO_USER(state, payload) {
            state.entryDataForTeamToUser = payload
        },

        CLEAR_ENTRY_DATA_FOR_TEAM_TO_USER(state) {
            state.entryDataForTeamToUser = null
        },

        SET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER(state, payload) {
            state.otherUserIsHaveEntriesTeamToUser = payload
        },

        SET_USER_TEAMS(state, payload) {
            state.userTeams = payload
        },
    },
    actions: {
        GET_SECRET({commit}) {
            return API({
                url: '/clientPassword',
                method: 'GET'
            }).then(({data}) => {
                commit('SET_SECRET', data);
            })
        },
        /* register and login stuff */
        SHOW_USER_AUTH_MODALS({commit}, data) {
            if (data.modal === undefined) {
                data.modal = 'register'
            }

            data.isAuth === false ? commit('SET_REG_MODALS', 'register') : null;

            commit('SET_REG_MODALS', data.modal)
            commit('SET_IS_AUTH', data.isAuth);
        },
        GO_BACK({commit, getters}) {
            commit('SET_REG_MODALS', getters.AUTH.prevRegModal)
            commit('SET_ERROR', '')
        },

        USER_REGISTRATION({commit, getters}, dataForRegistration) {
            commit('SET_ERROR', '');
            commit('SET_IS_REQUEST', true);
            const registerUser = async (type) => {
                try {
                    let dataToSend = {};
                    dataToSend[type] = dataForRegistration.inputValue;
                    dataToSend.hostname = window.location.hostname;

                    const res = await API({
                        method: 'post',
                        url: `/register/${type}`,
                        data: {
                            ...dataToSend,
                            login: dataForRegistration.login,
                            vk: dataForRegistration.vk
                        }
                    });
                    let codeUrlHeader = res.request.getResponseHeader('x-next');
                    const data = res.data;
                    commit('SET_REG_CODE_LIFE', getCodeLife(data.expires_at))
                    commit('SET_URL_TO_REG_CODE', codeUrlHeader)
                    commit('SET_REG_MODALS', 'confirm-registration')
                    commit('SET_AUTH_TYPE', type)
                    commit('SET_IS_REQUEST', false)
                } catch (err) {
                    commit('SET_ERROR', createErrorByResponse(err.response.data.errors));
                    setTimeout(() => {
                        commit('SET_ERROR', '')
                    }, 10000)
                    commit('SET_IS_REQUEST', false);
                }
            }

            switch (true) {
                case !!dataForRegistration.inputValue.match(/@/):
                    commit('SET_USER_EMAIL', dataForRegistration.inputValue);
                    registerUser('email');
                    break;
                default:
                    commit('SET_USER_PHONE', dataForRegistration.inputValue);
                    registerUser('phone');
                    break;
            }
        },
        CONFIRM_USER_REGISTRATION({commit, dispatch, getters}, confirmData) {
            commit('SET_ERROR', '');
            commit('SET_IS_REQUEST', true);

            let data = {};
            switch (getters.AUTH.authType) {
                case 'phone':
                    data = {
                        "verification": {
                            "phone": getters.USER.attributes.phone,
                            "code": `${confirmData.code}`
                        }
                    }
                    break;
                case 'email':
                    data = {
                        "verification": {
                            "email": getters.USER.attributes.email,
                            "code": `${confirmData.code}`
                        }
                    }
                    break;
                default:
                    break;
            }

            return API({
                method: 'post',
                url: getters.CODE_URL_HEADER,
                data
            })
                .then(response => {
                    let tokens = {
                        access: response.data.accessToken
                    }
                    commit('SET_USER_TOKEN', tokens)
                    commit('SET_AUTH', true)
                    commit('SET_IS_AUTH', false)
                    dispatch('GET_SINGLE_USER')
                    commit('SET_IS_REQUEST', false);
                })
                .catch(error => {
                    commit('SET_ERROR', createErrorByResponse(error.response.data.errors));
                    setTimeout(() => {
                        commit('SET_ERROR', '')
                    }, 10000)
                    commit('SET_IS_REQUEST', false);
                })
        },

        AUTH_USER({commit, getters, dispatch}, data = {}) {
            commit('SET_ERROR', '');
            commit('SET_IS_REQUEST', true);
            const authUser = () => {
                return axios({
                    method: 'post',
                    url: BACKEND_URL + '/oauth/token',
                    headers: {
                        'Content-Type': 'application/vnd.api+json',
                        'Accept': 'application/vnd.api+json'
                    },
                    data: {
                        "grant_type": "password",
                        "username": data.login,
                        "password": data.password,
                        "client_secret": getters.CLIENT_PASSWORD,
                        "client_id": getters.CLIENT_ID,
                        "scope": "*",
                        "hostname": window.location.hostname
                    }
                })
                    .then(response => {
                        let tokens = {
                            access: response.data.access_token,
                            refresh: response.data.refresh_token,
                            expires_in: response.data.expires_in
                        }
                        commit('SET_USER_TOKEN', tokens)
                        commit('SET_IS_AUTH', false)
                    })
                    .then(() => {
                        commit('SET_IS_REQUEST', false)

                        // Эта часть кода будет отвечать за то, чтобы показывать модалку ПОСЛЕ авторизации
                        if (JSON.parse(localStorage.getItem('afterAuthParticipateInTournament'))) {
                            sessionStorage.setItem('reload', 'true')
                        }

                    })
                    .then(() => {
                        document.location.reload();
                    })
                    .catch((error) => {
                        commit('SET_ERROR', createErrorByResponse(error.response.data.errors));
                        setTimeout(() => {
                            commit('SET_ERROR', '')
                        }, 10000)
                        commit('SET_IS_REQUEST', false);
                    })
            }

            authUser();

        },
        async REFRESH_USER_TOKEN({commit, dispatch, getters}) {
            if (localStorage.getItem('expiresTokenDate') <= DATE_NOW) {
                try {
                    const response = await axios({
                        method: 'post',
                        mode: 'no-cors',
                        url: BACKEND_URL + '/oauth/token',
                        headers: {
                            'Content-Type': 'application/vnd.api+json',
                            'Accept': 'application/vnd.api+json'
                        },
                        data: {
                            "grant_type": "refresh_token",
                            "refresh_token": localStorage.getItem('refreshAccessToken'),
                            "client_secret": getters.CLIENT_PASSWORD,
                            "client_id": getters.CLIENT_ID,
                            "scope": "*"
                        }
                    });

                    let tokens = {
                        access: response.data.access_token,
                        refresh: response.data.refresh_token,
                        expires_in: response.data.expires_in
                    };
                    commit('SET_USER_TOKEN', tokens);
                    // commit('SET_AUTH', true);
                    commit('SET_IS_AUTH', false);
                    await dispatch('GET_SINGLE_USER', true);
                    dispatch('AUTO_REFRESH_TOKEN', 10800000);
                } catch (error) {
                    localStorage.clear();
                }
            } else {
                await dispatch('GET_SINGLE_USER', true);
            }
        },
        AUTO_REFRESH_TOKEN({dispatch}, expTime) {
            setTimeout(() => {
                dispatch('REFRESH_USER_TOKEN')
            }, expTime)
        },

        RECOVER_PASSWORD({commit}, dataToRecover) {
            commit('SET_ERROR', '');
            commit('SET_IS_REQUEST', true);

            //TODO РЕФАКТОРИНГ
            switch (dataToRecover.type) {
                case 'phone':
                    commit('SET_USER_PHONE', dataToRecover.inputValue)
                    commit('SET_AUTH_TYPE', 'phone')
                    return API({
                        method: 'POST',
                        url: '/forget/password/phone',
                        data: {
                            "phone": dataToRecover.inputValue
                        }
                    }).then(({data}) => {
                        commit('SET_REG_CODE_LIFE', getCodeLife(data.expires_at))
                        commit('SET_REG_MODALS', 'confirm-recover')
                        commit('SET_IS_REQUEST', false);
                    }).catch(error => {
                        commit('SET_ERROR', createErrorByResponse(error.response.data.errors));
                        setTimeout(() => {
                            commit('SET_ERROR', '')
                        }, 10000)
                    });
                case 'email':
                    console.log('EMAIL RECOVER')
                    commit('SET_USER_EMAIL', dataToRecover.inputValue)
                    commit('SET_AUTH_TYPE', 'email')
                    return API({
                        method: 'POST',
                        url: '/forget/password/email',
                        data: {
                            "email": dataToRecover.inputValue
                        }
                    }).then(({data}) => {
                        commit('SET_REG_CODE_LIFE', getCodeLife(data.expires_at))
                        commit('SET_REG_MODALS', 'confirm-recover')
                        commit('SET_IS_REQUEST', false);
                    }).catch(error => {
                        commit('SET_ERROR', createErrorByResponse(error.response.data.errors));
                        setTimeout(() => {
                            commit('SET_ERROR', '')
                        }, 10000)
                    });
                default:
                    const errText = generateErrorText('Проверьте правильность введенных данных. Телефон должен начинаться с +7', dataToRecover.inputValue)
                    commit('SET_ERROR', errText);
                    commit('SET_IS_REQUEST', false);
                    break;
            }
        },
        CONFIRM_PASSWORD_RECOVER({commit, dispatch, getters}, confirmData) {
            commit('SET_ERROR', '');
            let data = {};
            if (getters.AUTH.authType === 'phone') {
                data = {
                    "verification": {
                        "phone": getters.USER.attributes.phone,
                        "code": `${confirmData.code}`
                    },
                    "password": `${confirmData.password}`
                }
            } else {
                data = {
                    "verification": {
                        "email": getters.USER.attributes.email,
                        "code": `${confirmData.code}`
                    },
                    "password": `${confirmData.password}`
                }
            }
            let url = getters.AUTH.authType === 'phone' ? 'forget/password/phone/code' : 'forget/password/email/code';
            return API({
                method: 'post',
                url,
                data
            })
                .then(response => {
                    let tokens = {
                        access: response.data.accessToken
                    }
                    commit('SET_USER_TOKEN', tokens)
                    commit('SET_AUTH', true)
                    commit('SET_IS_AUTH', false)
                    dispatch('GET_SINGLE_USER')
                })
                .catch(error => {
                    commit('SET_ERROR', createErrorByResponse(error.response.data.errors));
                    setTimeout(() => {
                        commit('SET_ERROR', '')
                    }, 10000)
                })
        },

        /* users stuff */
         async GET_SINGLE_USER({commit, dispatch, getters}, needUpdate = false) {
            commit('SET_IS_REQUEST', true);
            let getData = new FetchingData({
                url: '/users/profile',
                params: '?include=teams.logo,logo,background,teams.compositionsForDisciplines',
                method: 'GET',
                headers: {'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`}
            })

             const staticData = await getData.getStaticData();
             commit('SET_LANGUAGES', staticData.languages.attributes.data);
             commit('SET_COUNTRIES', staticData.countries.attributes.data);
             commit('SET_TIMEZONES', staticData.timeZones.attributes.data);

            if (needUpdate === true) {
                await getData.createUser().then(data => {
                    commit('SET_USER_TO_STATE', data);
                    localStorage.setItem('user', JSON.stringify(data));
                    commit('SET_AUTH', true);
                }).then(() => {
                    commit('SET_IS_REQUEST', false);
                    dispatch('AUTO_REFRESH_TOKEN', 10800000);
                }).catch(err => {
                    if (err.response?.status === 401) {
                        commit('SET_IS_REQUEST', false);
                    }
                });

                return true
            } else {
                if (localStorage.getItem('user') !== null) {
                    let user = JSON.parse(localStorage.getItem('user'));
                    commit('SET_USER_TO_STATE', user);
                    commit('SET_AUTH', true);
                    dispatch('AUTO_REFRESH_TOKEN', 10800000);
                } else {
                    await getData.createUser().then(data => {
                        commit('SET_USER_TO_STATE', data);
                        localStorage.setItem('user', JSON.stringify(data));
                        commit('SET_AUTH', true);
                    }).then(() => {
                        commit('SET_IS_REQUEST', false);
                        dispatch('AUTO_REFRESH_TOKEN', 10800000);
                    }).catch(err => {
                        if (err.response?.status === 401) {
                            commit('SET_IS_REQUEST', false);
                        }
                    });
                }
            }


            dispatch('SET_USER_CAPTAIN_TEAMS');

            if (!localStorage.getItem('afterAuthParticipateInTournament'))
                localStorage.setItem('afterAuthParticipateInTournament', 'false');


        },

        GET_USER_REQUESTED_TEAMS({commit}, data) {
            commit('SET_IS_REQUEST', true);
            return API({
                url: `/entries/?filter[sendable_id]=${data.userId}&filter[sendable_type]=users&filter[receivable_type]=teams&filter[status]=pending&include=receivable,receivable.logo,receivable.games`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                }
            }).then((response) => {
                let data = response.data;
                if (data.data.length) {
                    let logos = includeFilteringByTag(data.included, 'logo');
                    let requestedTeams = includeFilteringByType(data.included, 'teams').map(team => {
                        team.logo = logos.filter(logo => logo.id === team.relationships.logo.data.id)[0].attributes.url
                        team.userRole = team.relationships.users.meta.roles.find(role => {
                            if (Number(role.id) === Number(data.id)) {
                                return role.role
                            }
                        });
                        return team
                    })
                    console.log(requestedTeams)
                    commit('SET_USER_REQUESTED_TEAMS', requestedTeams)
                }
                commit('SET_IS_REQUEST', false);
            })
        },
        /* Update user data actions */
        UPDATE_USER_DATA({commit, getters}, data) {
            commit('SET_IS_REQUEST', true);
            return API({
                url: `users/${getters.USER.id}`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    data: {
                        "type": "users",
                        "id": `${getters.USER.id}`,
                        "attributes": {...data}
                    }
                }
            })
                .then(({data}) => {
                    commit('UPDATE_USER_ATTRIBUTES', data.data.attributes);
                    commit('SET_IS_REQUEST', false);
                })
                .then(() => {
                    let updatedUser = {
                        data: getters.USER,
                        teams: getters.USER_TEAMS,
                        managedTeams: getters.USER_MANAGED_TEAMS
                    }
                    sessionStorage.setItem('user', JSON.stringify(updatedUser));
                })
                .catch(err => {
                    commit('SET_IS_REQUEST', false);
                    return err
                });
        },
        UPDATE_USER_SECURITY_INFO({commit, getters}, data) {
            commit('SET_ERROR', '');
            let url;
            let sendingInfo = {};
            switch (data.type) {
                case 'password':
                    url = 'users/profile/change/password'
                    sendingInfo = {
                        "password": data.value,
                        "password_confirmation": data.value
                    }
                    break
                case 'email':
                    url = 'users/profile/change/email'
                    sendingInfo = {
                        "email": data.value
                    }
                    break
                case 'phone':
                    url = 'users/profile/change/phone'
                    sendingInfo = {
                        "phone": data.value
                    }
                    break
                default:
                    return null;
            }
            return API({
                url: url,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {...sendingInfo}
            })
                .then((response) => {
                    switch (data.type) {
                        case 'email':
                            commit('SET_USER_EMAIL', sendingInfo.email)
                            break
                        case 'phone':
                            commit('SET_USER_PHONE', sendingInfo.phone)
                            break
                        default:
                            return null;
                    }
                }).then(() => {
                    let updatedUser = {
                        data: getters.USER,
                        teams: getters.USER_TEAMS,
                        managedTeams: getters.USER_MANAGED_TEAMS
                    }
                    sessionStorage.setItem('user', JSON.stringify(updatedUser));
                })
                .catch(err => {
                    commit('SET_ERROR', createErrorByResponse(err.response.data.errors));
                    setTimeout(() => {
                        commit('SET_ERROR', '')
                    }, 10000)
                });
        },

        UPLOAD_AVATAR({commit, dispatch, getters}, data) {
            let formData = new FormData();
            formData.append('file', data.file)
            formData.append('tag', data.tag)
            return API({
                url: `/files/upload`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
                .then(res => {
                    dispatch('PATCH_AVATAR', {id: res.data.data.id})
                    commit('SET_USER_AVATAR', res.data.data.attributes.url)
                }).then(() => {
                    let updatedUser = {
                        data: getters.USER,
                        teams: getters.USER_TEAMS,
                        managedTeams: getters.USER_MANAGED_TEAMS
                    }
                    sessionStorage.setItem('user', JSON.stringify(updatedUser));
                })
        },
        PATCH_AVATAR({commit, getters}, data) {
            return API({
                url: `/users/${getters.USER.id}/relationships/logo`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                },
                data: {
                    'data': {
                        'type': 'attachments',
                        "id": data.id
                    }
                }
            })
        },

        UPLOAD_USER_BACKGROUND({commit, dispatch}, data) {
            let formData = new FormData();
            formData.append('file', data.file)
            formData.append('tag', data.tag)
            return API({
                url: `/files/upload`,
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    'Content-Type': 'multipart/form-data'
                },
                data: formData
            })
                .then(res => {
                    dispatch('PATCH_USER_BACKGROUND', {id: res.data.data.id})
                    commit('SET_USER_BACKGROUND', res.data.data.attributes.url)
                })
        },
        PATCH_USER_BACKGROUND({commit, getters}, data) {
            return API({
                url: `/users/${getters.USER.id}/relationships/background`,
                method: 'PATCH',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                },
                data: {
                    'data': {
                        'type': 'attachments',
                        "id": data.id
                    }
                }
            })
        },

        LOGG_OUT() {
            localStorage.clear();
            sessionStorage.clear();
            location.reload()
        },

        GET_USER_FROM_URL({commit, dispatch}, data) {
            commit('SET_IS_REQUEST', true);
            let getData = new FetchingData({
                url: `/users/${data.id}`,
                params: '?include=teams.logo,logo,background',
                method: 'GET',
            });

            getData.createUser().then(data => {
                commit('SET_ANOTHER_USER_TO_STATE', data);
            }).then(() => {
                commit('SET_IS_REQUEST', false);
            })
        },

        GET_USER_ENTRIESES_TO_TEAM({commit, getters}, data) {

            const accessToken = localStorage.getItem('userAccessToken');

            API({
                url: `/entries?filter[sendable_type]=users&filter[sendable_id]=${getters.USER.id}&filter[status]=pending&filter[receivable_id]=${data.teamId}`,
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${accessToken}`
                },
            })
                .then(response => {
                    if (response.data.data.length !== 0) {
                        commit('SET_USER_REQUEST_TO_TEAM', true)
                    }
                })
        },

        // Action, который смотрит, есть ли у нас заявки от команды к пользователю
        // Если такие заявки есть, то будет отображаться кнопка "принять заявку"
        async SET_IS_HAVE_ENTRIES_TEAM_TO_USER({commit}, teamId) {

            try {
                const accessToken = localStorage.getItem('userAccessToken');
                const userId = JSON.parse(localStorage.getItem('user')).data.id

                const response = await API({
                    method: 'get',
                    url: `/entries?filter[kind]=TeamToUser&filter[sendable_id]=${teamId}&filter[receivable_id]=${userId}`,
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    },
                })


                // Если есть какие-то заявки от данной команды, то записываем ответ
                if (response.data.data.length !== 0) {
                    commit('SET_ENTRY_DATA_FOR_TEAM_TO_USER', response.data.data)

                    // Если мы получили ответ со статусом pending,
                    // то показываем кнопку "принять заявку"
                    if (response.data.data[0].attributes.status === 'pending') {
                        commit('SET_IS_HAVE_ENTRIES_TEAM_TO_USER', 'pending')
                    } else if (response.data.data[0].attributes.status === 'confirmed') {
                        commit('SET_IS_HAVE_ENTRIES_TEAM_TO_USER', 'confirmed')
                    }
                }

            } catch (e) {
                return null
                // throw new Error('SET_IS_HAVE_ENTRIES_TEAM_TO_USER')
            }
        },

        CLEAR_ENTRIES_TEAM_TO_USER({commit}) {
            commit('SET_IS_HAVE_ENTRIES_TEAM_TO_USER', false)
        },

        CLEAR_ENTRY_DATA_FOR_TEAM_TO_USER({commit}) {
            commit('CLEAR_ENTRY_DATA_FOR_TEAM_TO_USER')
        },

        GET_USER_MT_USERS_LIST({commit, getters}, data) {
            let url = getters.USER_MANAGED_TEAMS[data.index].relationships.users.links.related;
            return API({
                url: url + '?include=logo',
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                }
            })
        },

        SET_USER_CAPTAIN_TEAMS({commit, getters}) {
            try {
                // Получаю user id
                const userId = String(getters.USER.id);

                // Получаю команды
                const teams = [...getters.USER_TEAMS];

                // Получаю составы по дисциплинам, которые есть в наших командах
                const compositionsForDisciplines = [...getters.USER_COMPOSITIONS_FOR_DISCIPLINES];

                // Переменная, в которую мы будем заносить команды, в составах которой пользователь является капитаном
                const teamsWithCaptainByMyself = [];

                // Проходим по циклам. Собираем нужные нам команды
                for (let team of compositionsForDisciplines) {
                    for (let role of team.relationships.users.meta.roles) {
                        if (String(role.id) === userId && role.role === 'capitan') {
                            const searchTeam = teams.find((item) => String(item.id) === String(team.attributes['team_id']))
                            if (searchTeam) teamsWithCaptainByMyself.push(searchTeam);
                            break
                        }
                    }
                }

                commit('SET_USER_CAPTAIN_TEAMS', teamsWithCaptainByMyself);

            } catch (e) {
                console.log(`user SET_USER_CAPTAIN_TEAMS ${e}`);
                throw new Error(`user SET_USER_CAPTAIN_TEAMS ${e}`);
            }
        },

        SET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER({commit}, payload) {
            commit('SET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER', payload)
        },

        getUsersFromIds({commit, id}) {
            console.log(id)
            API({
                url: `/users?filter[id]=${id}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    'Content-Type': 'multipart/form-data',
                    'X-SOCKET-ID': ActiveEcho.socketId()
                },
            }).then(res => {
                console.log(res)
                this.usersFromIds.push(res.data.data);
            })
        },

        DELETE_USER_TEAMS({commit, getters}, payload) {
            const newUserTeams = getters.USER_TEAMS.filter(team => String(team.id) !== String(payload))
            commit('SET_USER_TEAMS', newUserTeams);
        }
    },
    getters: {
        CAN_U_GET_DATA_AFTER_USER_AUTH(state) {
            return state.canUDoReqWithUser;
        },

        GET_REGISTER_AFTER_AUTH(state) {
            return state.registerAfterAuth
        },
        CLIENT_PASSWORD(state) {
            return state.client_secret;
        },
        CLIENT_ID(state) {
            return state.client_id;
        },
        CODE_URL_HEADER(state) {
            return state.urlToRegistrationCode
        },

        USER(state) {
            return state.user;
        },
        USER_TEAMS(state) {
            return state.userTeams;
        },
        USER_REQUESTED_TEAMS(state) {
            return state.userRequestedTeams;
        },
        USER_COMPOSITIONS_FOR_DISCIPLINES(state) {
            return state.user.compositionsForDisciplines;
        },
        USER_MANAGED_TEAMS(state) {
            return state.userManagedTeams;
        },

        USER_CAPTAIN_TEAMS(state) {
            return state.userCaptainTeams;
        },

        GET_CAPTAIN_AND_MANAGED_TEAMS(state) {
            // Функция, которая превращает объект в массив объектов
            const generateUniqueTeams = (generateObj) => {
                const newArray = [];

                for (const key in generateObj) {
                    if (Object.prototype.hasOwnProperty.call(generateObj, key)) newArray.push(generateObj[key])
                }

                return newArray;
            }

            // Собираю массив из команд менеджера и команд, где пользователь капитан, однако в этом массиве могут быть дублликаты-объекты. Надо от них избавиться

            const allArray = [...state.userManagedTeams, ...state.userCaptainTeams]

            const generateObj = {};
            allArray.forEach((item) => (generateObj[item.id] = item))

            return generateUniqueTeams(generateObj)
        },



        ANOTHER_USER_TEAMS(state) {
            return state.anotherUserTeams;
        },
        ANOTHER_USER_MANAGED_TEAMS(state) {
            return state.anotherUserManagedTeams;
        },


        AUTH(state) {
            return state.auth
        },
        ANOTHER_USER(state) {
            return state.anotherUser
        },
        ACTIVE_REQUEST(state) {
            return state.isUserRequestToTeam
        },

        GET_USER_IS_AUTHENTICATE(state) {
            return state.auth.isAuthenticate
        },

        REG_CODE_EXP(state) {
            return state.auth.regCodeLife
        },

        usersFromIds(state) {
            return state.usersFromIds;
        },

        GET_IS_HAVE_ENTRIES_TEAM_TO_USER(state) {
            return state.isUserHaveEntriesTeamToUser
        },

        GET_ENTRY_DATA_FOR_TEAM_TO_USER(state) {
            return state.entryDataForTeamToUser
        },

        GET_OTHER_USER_IS_HAVE_ENTRIES_TEAM_TO_USER(state) {
            return state.otherUserIsHaveEntriesTeamToUser;
        },
        uIsAdmin(state){
            return state.user.attributes.role === 'admin';
        }
    },
}
