<template>
  <div class="profile-page" v-if="isShow">
    <profile-header/>
    <profile-nav/>
    <div class="container profile-container">
      <transition name="default">
        <router-view></router-view>
      </transition>
    </div>
  </div>
  <profile-sceleton v-else />
</template>

<script>
import ProfileHeader from "@/components/profile/profileHeader";
import ProfileNav from "@/components/profile/profileNav";
import {mapGetters, mapActions} from 'vuex';
import ProfileSceleton from "@/views/sceleton/profile-sceleton";

export default {
  name: "profile",
  components: {ProfileSceleton, ProfileNav, ProfileHeader},
  data() {
    return {
      user: {},
      isShow: true
    }
  },
  computed: {
    ...mapGetters([
      'AUTH',
      'USER'
    ]),
  },
  methods: {
    ...mapActions([
        'GET_SINGLE_USER',
        'GET_USER_REQUESTED_TEAMS'
    ]),
    async getUser() {

      await this.GET_SINGLE_USER(true)
      await this.GET_USER_REQUESTED_TEAMS({userId: this.USER.id})

    }
  },
  async created() {
    this.isShow = false
    await this.getUser()
    this.isShow = true
  }
}
</script>

<style lang="scss">
.profile-page {
  margin-top: 64px;
  padding-bottom: 112px;
}

@media (max-width: 580px) {
  .profile-container {
    max-width: 100vw;
  }
}
</style>