<template>
  <div class="universalModal" @click="closeModal">
    <div class="universalModal__card" @click.stop>
      <div class="universalModal__closeButton" @click="closeModal">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M3 3L21 21M21 3L3 21" stroke="white"/>
        </svg>
      </div>
      <p
          class="universalModal__text"
          :class="{'universalModal__text_default': isStatusDefault, 'universalModal__text_warning': isStatusWarning}"
      >
        {{ getModalText }}
      </p>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "universal-modal",
  data() {
    return {
      timer: null
    }
  },
  methods: {
    ...mapActions([
      'SET_CONFIG_UNIVERSAL_MODAL',
      'SET_INACTIVE_UNIVERSAL_MODAL'
    ]),
    closeModal() {
      this.SET_INACTIVE_UNIVERSAL_MODAL()
    }
  },

  computed: {
    ...mapGetters([
      'GET_CONFIG_UNIVERSAL_MODAL'
    ]),

    getModalText() {
      return this.GET_CONFIG_UNIVERSAL_MODAL.text
    },

    getModalStatus() {
      return this.GET_CONFIG_UNIVERSAL_MODAL.status
    },

    isStatusDefault() {
      return this.getModalStatus === 'default'
    },

    isStatusWarning() {
      return this.getModalStatus === 'warning'
    }
  },

  mounted() {
    this.timer = setTimeout(() => {
      this.closeModal()
    }, 3000)
  },

  destroyed() {
    clearTimeout(this.timer);
  }
}
</script>

<style lang="scss" scoped>

@import "src/scss/vars/colors";
@import "src/scss/vars/mixins";

.universalModal {
  z-index: 9999999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100vh;
  max-height: 100%;
  background-color: rgba(0, 0, 0, .6);

  &__card {
    position: relative;
    max-width: 375px;
    width: 100%;
    border-radius: 8px;
    background-color: $elemBg;
    max-height: 250px;
    height: 100%;
    padding: 0 33px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  &__text {
    text-transform: uppercase;
    font-family: $blatant-cyrillic, $blatant-latin;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: .2px;

    &_default {
      color: $yellow;
    }

    &_warning {
      color: $red;
    }
  }

  &__closeButton {
    cursor: pointer;
    right: 24px;
    top: 24px;
    position: absolute;
    width: 24px;
    height: 24px;

    svg {
      width: 100%;
      height: 100%;
    }
  }
}
</style>