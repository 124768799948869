export const includeFilteringByType = (included, type) => {
    return included.filter(item => item.type === type);
}
export const includeFilteringByTag = (included, tag) => {
    return included.filter(item => item.attributes.tag === tag);
}

export const getDataForRelation = (included, relationData) => {
    return included.filter(item => item.type === relationData.type && item.id === relationData.id)[0];
};

export const filteringByUserRole = (arr, role) => {
    return arr.filter(item => item.userRole === role);
};

// Функция, позволяющая получить какие-либо определенные данные из included сущности attachments
export const attachmentsGetFromInclude = (itemData, included, tag) => {
    return included.find((include) =>
        include.type === 'attachments'
        && include.attributes.tag === tag
        && String(itemData.relationships[tag].data.id) === String(include.id))
}

// Специальная функция для attachments скриншотов
export const attachmentsGetFromIncludeForScreenshots = (itemData, included, tag) => {
    return included.filter((include) =>
        include.type === 'attachments'
        && include.attributes.tag === tag
        && itemData.relationships.attachments.data.some(someItem => String(someItem.id) === String(include.id)))
}

export const gameGetFromInclude = (itemData, included) => {
    return included.find((include) =>
        include.type === 'games'
        && String(itemData.relationships.game.data.id) === String(include.id))
}

export const includeFindingByTag = (included, tag) => {
    return included.find(item => item.attributes.tag === tag);
}

