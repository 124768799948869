import {API} from '../axios-config';
import {attachmentsGetFromInclude, gameGetFromInclude, includeFilteringByTag} from "@/otherJS/include-filtering";
import {includeFilteringByType} from "@/otherJS/include-filtering";
import filterForEventGroups from "@/otherJS/filterForEventGroups";

export default {
    state: {
        groupsOfTournaments: [],
        ratingsList: [],
        ratingsPageMeta: {},
        eventGroupsTournaments: null,
        selectedFilter: {
            name: 'all',
            value: null
        },
        selectedSort: 'started_at',
        typeOfSort: '-',  // - по убыванию, + по возрастанию
        countOfAllTournaments: null,
    },
    mutations: {
        SET_GROUPS_OF_TOURNAMENTS_TO_STATE: (state, data) => state.groupsOfTournaments = data,
        SET_RATINGS_META: (state, data) => state.ratingsPageMeta = data,
        SET_RATINGS_LIST_TO_STATE: (state, data) => {
            state.ratingsList.length ? state.ratingsList = state.ratingsList.concat(data) : state.ratingsList = data
        },
        CLEAR_RATINGS_LIST: (state) => state.ratingsList = [],
        SET_EVENT_GROUPS_TOURNAMENTS(state, payload) {
            state.eventGroupsTournaments = payload
        },

        SET_MORE_EVENT_GROUPS_TOURNAMENTS(state, payload) {
            const tempState = {...state.eventGroupsTournaments};

            tempState.meta = {...payload.meta};
            tempState.included = [...tempState.included, ...payload.included];
            tempState.data = [...tempState.data, ...payload.data];

            state.eventGroupsTournaments = tempState;
        },

        SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS(state, payload) {
            state.selectedFilter = payload
        },

        SET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS(state, payload) {
            state.countOfAllTournaments = payload
        },

        SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS(state, payload) {
            state.selectedSort = payload
        },

        SET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS(state, payload) {
            state.typeOfSort = payload
        },
    },
    actions: {
        async GET_GROUPS_OF_TOURNAMENTS({commit, getters, dispatch}) {
            const res = await API({
                url: '/groups-of-tournaments?include=ratings.participantables,tournaments,game,game.logo,background,sponsor,sponsor.logo&fields[game]=id',
                method: "GET"
            });

            const data = res.data.data
            const included = res.data.included

            const backgrounds = includeFilteringByTag(included, 'background');
            const sponsors = includeFilteringByType(included, 'sponsors');
            const logos = includeFilteringByTag(included, 'logo');
            const ratings = includeFilteringByType(included, 'intra-group-ratings');

            sponsors.map(item => {
                item.logo = logos.find(singleLogo => String(singleLogo.id) === String(item.relationships.logo.data.id)).attributes.url
            })

            data.map(item => {
                item.background = backgrounds.find(el => String(el.id) === String(item.relationships.background.data.id)).attributes.url
                item.sponsor = sponsors.find(el => Number(el.id) === Number(item.attributes.sponsor_id))
                item.game_logo = getters.GAMES.find(el => Number(el.id) === Number(item.attributes.game_id)).logo
                item.game_name = getters.GAMES.find(el => Number(el.id) === Number(item.attributes.game_id)).attributes.name
                item.ratings_list = ratings.filter(el => Number(el.id) === Number(item.id))[0]
            })

            commit('SET_GROUPS_OF_TOURNAMENTS_TO_STATE', data);
        },

        async GET_RATINGS_TABLES_IN_GROUP({commit}, dataToRequest) {
            const res = await API({
                url: `/intra-group-rating-participantables?include=participantable&filter[intra_group_rating_id]=${dataToRequest.groupId}&sort=-rating&page[size]=${dataToRequest.page_size}&page[number]=${dataToRequest.page_number}`,
                method: "GET"
            });

            if (dataToRequest.page_number === 1) commit('CLEAR_RATINGS_LIST')

            const data = res.data.data
            if (data.length) {
                const includes = res.data.included

                const participants = includeFilteringByType(includes, data[0].attributes.participantable_type);

                participants.map(item => {
                    item.rating = data.find(el => Number(el.attributes.participantable_id) === Number(item.id)).attributes.rating
                })

                // console.log(participants)

                commit('SET_RATINGS_LIST_TO_STATE', participants)
            }
            commit('SET_RATINGS_META', res.data.meta.page)
        },

        async SET_EVENT_GROUPS_TOURNAMENTS({commit, getters, dispatch}, groupOfTournamentId) {
            try {
                const selectedFilter = getters.GET_SELECTED_FILTER_FOR_EVENTS;
                const selectedSort = getters.GET_SELECTED_SORT_FOR_EVENTS;
                const typeOfSort = getters.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS;

                const response = await API(filterForEventGroups(selectedFilter, selectedSort, typeOfSort, groupOfTournamentId))

                if (selectedFilter.name === 'all')
                    dispatch('SET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS', response.data.meta.page.total)

                const included = response.data.included;

                response.data.data = response.data.data.map(item => {
                    item.background = attachmentsGetFromInclude(item, included, 'background').attributes.url;
                    item.game = gameGetFromInclude(item, included).attributes.name;

                    return item
                });

                commit('SET_EVENT_GROUPS_TOURNAMENTS', response.data)
            } catch (e) {
                console.log('SET_EVENT_GROUPS_TOURNAMENTS', e)
                throw new Error(`SET_EVENT_GROUPS_TOURNAMENTS ${e}`)
            }
        },

        async SET_MORE_EVENT_GROUPS_TOURNAMENTS({commit, getters, dispatch}, groupOfTournamentId) {
            try {
                const selectedFilter = getters.GET_SELECTED_FILTER_FOR_EVENTS;
                const selectedSort = getters.GET_SELECTED_SORT_FOR_EVENTS;
                const typeOfSort = getters.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS;
                const nextPage = Number(getters.GET_EVENT_GROUPS_META.page['current-page']) + 1

                const response = await API(filterForEventGroups(selectedFilter, selectedSort, typeOfSort, groupOfTournamentId, nextPage))

                const included = response.data.included;

                response.data.data = response.data.data.map(item => {
                    item.background = attachmentsGetFromInclude(item, included, 'background').attributes.url;
                    item.game = gameGetFromInclude(item, included).attributes.name;

                    return item
                });

                commit('SET_MORE_EVENT_GROUPS_TOURNAMENTS', response.data);

            } catch (e) {
                console.log('SET_MORE_EVENT_GROUPS_TOURNAMENTS', e);
                throw new Error(`SET_MORE_EVENT_GROUPS_TOURNAMENTS ${e}`)
            }
        },

        SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS({commit}, payload) {
            commit('SET_SELECTED_FILTER_FOR_GROUPS_TOURNAMENTS', payload)
        },

        SET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS({commit}, payload) {
            commit('SET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS', payload)
        },

        SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS({commit}, payload) {
            commit('SET_SELECTED_SORT_FOR_GROUPS_TOURNAMENTS', payload)
        },

        TOGGLE_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS({commit, getters}) {
            const typeOfSort = getters.GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS;

            if (typeOfSort === '-') commit('SET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS', '+')
            else commit('SET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS', '-')
        },

        async GET_ACTIVE_STREAM({commit, getters}, sponsorId) {
            const res = await API({
                url: `/broadcast-tournament/${sponsorId}`,
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
            });

            return res
        }

    },
    getters: {
        GROUPS_OF_TOURNAMENTS(state) {
            return state.groupsOfTournaments
        },
        RATINGS_LIST(state) {
            return state.ratingsList
        },
        RATINGS_LIST_META(state) {
            return state.ratingsPageMeta
        },
        GET_EVENT_GROUPS_TOURNAMENTS(state) {
            try {
                return state.eventGroupsTournaments.data
            } catch (e) {
                return []
            }
        },

        GET_IS_HAVE_GROUPS_TOURNAMENTS(state) {
            return Boolean(state.eventGroupsTournaments)
        },

        GET_IS_ALL_DATA_LOADED(state) {
            try {
                const currentPage = state.eventGroupsTournaments.meta.page['current-page'];
                const lastPage = state.eventGroupsTournaments.meta.page['last-page'];

                return currentPage === lastPage;

            } catch (e) {
                return true
            }
        },

        GET_EVENT_GROUPS_META(state) {
            try {
                return state.eventGroupsTournaments.meta
            } catch (e) {
                return []
            }
        },

        GET_SELECTED_FILTER_FOR_EVENTS(state) {
            return state.selectedFilter
        },

        GET_SELECTED_SORT_FOR_EVENTS(state) {
            return state.selectedSort
        },

        GET_COUNT_OF_ALL_TOURNAMENTS_IN_EVENT_GROUPS(state) {
            return state.countOfAllTournaments
        },

        GET_TYPE_OF_SORT_FOR_GROUPS_TOURNAMENTS(state) {
            return state.typeOfSort
        }
    }
}
