<template>
  <div class="sgl-footer--wrapper">
    <div class="rel-wrapper">
      <div class="container">
        <div class="sgl-footer">
          <a href="/" class="sgl-footer--logo">
            <svg width="270" height="59" viewBox="0 0 270 59" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M51.6149 24.1686H29.3396C14.1673 24.1277 13.3523 21.0996 13.3523 17.4924C13.3523 13.8513 17.7487 10.3256 26.6509 10.3256C26.8507 10.3256 48.9051 10.3291 49.105 10.3325C57.801 10.5129 66.2902 14.5834 66.2902 14.5834L69.8935 5.02204C69.8935 5.02204 62.0696 0.0284771 49.3697 0.0284771C49.1638 0.0284771 27.0969 0.0284664 26.894 0.0319238C13.0394 0.209113 3.3816 6.3779 3.3816 18.9367C3.3816 29.5165 10.7507 34.4418 24.243 34.4418H46.4909C59.6553 34.4896 62.834 37.0239 62.834 41.6801C62.834 46.2786 57.7192 48.5518 49.0482 48.6524C48.8483 48.6553 26.7878 48.6553 26.5816 48.6553C17.5389 48.6553 4.32402 42.1554 4.32402 42.1554L0 51.594C0 51.594 12.8648 58.7479 26.7726 58.9985C27.0159 59.0014 49.1202 59.0043 49.3669 59.0043C63.5525 59.0043 72.6983 51.9916 72.6983 41.8261C72.6983 31.6606 65.3076 24.1686 51.6149 24.1686ZM194.695 30.3748V40.0076H208.917C206.221 45.6275 200.818 48.8814 191.946 48.9293H107.427C93.8125 48.9293 88.107 41.6222 88.107 29.4992C88.107 17.3763 93.812 10.0735 107.427 10.0735H191.929C206.681 10.128 212.377 16.5561 212.377 16.5561L219.775 10.1386C219.775 10.1386 211.44 0.0800468 191.965 -0.00292969C191.89 0.000527665 107.508 0.000534558 107.43 0.000534558C89.1318 0.000534558 77.2632 13.2065 77.2632 29.5033C77.2632 45.8001 89.1318 59.006 107.43 59.006H191.932C199.74 58.9657 206.359 56.5081 211.376 52.4428V58.1244H221.903V30.3748H194.695ZM240.256 0.853337H229.725V58.0855H269.997V48.451H240.256V0.853337Z"
                      fill="#E2FF31"/>
                <path
                    d="M131.811 13.8933L139.259 21.6349L131.713 29.4783L139.259 37.3218L131.812 45.0634L124.266 37.2199L116.72 45.0634L109.272 37.3218L116.818 29.4783L109.272 21.6349L116.72 13.8933L124.266 21.7367L131.811 13.8933Z"
                    fill="white"/>
                <path
                    d="M172.554 13.8933L180.001 21.6349L172.456 29.4783L180.001 37.3218L172.554 45.0634L165.008 37.2199L157.462 45.0634L150.014 37.3218L157.56 29.4783L150.014 21.6349L157.462 13.8933L165.008 21.7367L172.554 13.8933Z"
                    fill="white"/>
              </g>
              <defs>
                <clipPath id="clip0">
                  <rect width="270" height="59" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </a>
          <nav class="sgl-footer--nav">
            <ul>
              <li>
                <router-link to="/tournaments">Все турниры</router-link>
              </li>
              <li>
                <router-link to="/FAQ">FAQ</router-link>
              </li>
            </ul>
          </nav>
          <footer-component-socials :social-links="footerInfo.links"/>
          <div class="sgl-footer--bot-group">
            <a href="https://api.sgl.one/storage/uploads/sdNLmY1kCuiPvOWXddR1DjahbFGF4gVhU4v3aYYv.pdf" target="_blank">
              Пользовательское соглашение
            </a>

            <a href="https://api.sgl.one/storage/uploads/Y957EyudvrGikVQXoPV3GGSCRfzu5R2og1lsDOMe.pdf" target="_blank">
              Политика конфиденциальности
            </a>
            <span>SGL eSports holding company® {{ year }}</span>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FooterComponentSocials from "@/components/footer/footerComponent-socials";
import {API} from "@/Vuex/axios-config";
import {includeFilteringByType} from "@/otherJS/include-filtering";

export default {
  name: "footer-component",
  components: {FooterComponentSocials},
  data() {
    return {
      footerInfo: {},
      year: new Date().getFullYear()
    }
  },
  methods: {
    async getFooterInfo() {
      const response = await API({
        method: 'get',
        url: `/footer-infos?filter[sponsor_id]=2&include=socialLinks.logo,filePrivacyPolicy,fileUserAgreement`
      })

      if (response.data.data.length) {
        const footerInfo = {}

        const links = includeFilteringByType(response.data.included, 'social-links')
        const attachments = includeFilteringByType(response.data.included, 'attachments')

        for (let link of links) {
          for (let logo of attachments) {
            link.logo = attachments.find(el => String(el.id) === String(link.relationships.logo.data.id)).attributes.url
          }
        }

        footerInfo.links = links

        this.footerInfo = footerInfo
      }
    }
  },
  computed: {},
  mounted() {
    this.getFooterInfo()
  }
}
</script>

<style scoped lang="scss">
</style>