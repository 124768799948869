<template>
  <div class="defaultLayout">
    <div class="container">
      <transition name="default">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  name: 'container'
}
</script>

<style lang="scss">
.defaultLayout {
  padding-top: 40px;
  padding-bottom: 60px;
}
</style>
