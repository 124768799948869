<template>
  <transition name="modal-fade">
    <div v-if="AUTH.isAuthOpen" class="modal-bg">
      <div class="modal-content">
        <svg @click="closeModal" class="modal-close" width="24" height="24" viewBox="0 0 24 24" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path opacity="0.4" d="M3 3L21 21M21 3L3 21" stroke="white"/>
        </svg>

        <login-main v-if="AUTH.activeRegModal === 'register'"/>
        <confirm-registration v-if="AUTH.activeRegModal === 'confirm-registration'" />
        <login-password v-if="AUTH.activeRegModal === 'password-auth'" @changeModal="goTo($event)"/>
        <login-recover v-if="AUTH.activeRegModal === 'recover'"/>
        <confirm-recover v-if="AUTH.activeRegModal === 'confirm-recover'"/>

        <div class="modal-footer" v-if="AUTH.activeRegModal !== 'recover'">
          <div v-if="AUTH.activeRegModal === 'register'" class="title">Другие способы входа</div>
          <button v-if="AUTH.activeRegModal === 'register'" class="filled__grey" @click="goToPassAuth">Войти с паролем</button>
          <!--Социальные сети, пока уходят на йух-->
          <!-- <div class="soc-links">
            <a class="googleAuth" href="#">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                    fill="white"/>
                <path
                    d="M16.0004 11.7473C17.3023 11.7473 18.1806 12.3097 18.6813 12.7796L20.638 10.8691C19.4363 9.75204 17.8724 9.06641 16.0004 9.06641C13.2887 9.06641 10.9468 10.6226 9.80664 12.8874L12.0484 14.6285C12.6108 12.9568 14.1669 11.7473 16.0004 11.7473Z"
                    fill="#EA4335"/>
                <path
                    d="M22.656 16.1538C22.656 15.5837 22.6098 15.1677 22.5096 14.7363H16V17.3094H19.821C19.744 17.9488 19.328 18.9117 18.4036 19.5588L20.5914 21.2537C21.901 20.0442 22.656 18.2646 22.656 16.1538Z"
                    fill="#4285F4"/>
                <path
                    d="M12.0554 17.3713C11.9091 16.9398 11.8243 16.4776 11.8243 16C11.8243 15.5224 11.9091 15.0601 12.0477 14.6287L9.80596 12.8877C9.33604 13.8275 9.06641 14.883 9.06641 16C9.06641 17.117 9.33604 18.1724 9.80596 19.1123L12.0554 17.3713Z"
                    fill="#FBBC05"/>
                <path
                    d="M16.0005 22.9332C17.8725 22.9332 19.4441 22.3169 20.5919 21.2538L18.4041 19.5589C17.8186 19.9672 17.0328 20.2523 16.0005 20.2523C14.167 20.2523 12.6109 19.0428 12.0562 17.3711L9.81445 19.1121C10.9546 21.377 13.2888 22.9332 16.0005 22.9332Z"
                    fill="#34A853"/>
              </svg>
            </a>
            <a class="facebookAuth" href="#">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M32 16C32 7.16253 24.8375 0 16 0C7.16253 0 0 7.16253 0 16C0 23.9875 5.85 30.6063 13.5 31.8063V20.625H9.43747V16H13.5V12.475C13.5 8.4656 15.8875 6.25 19.5437 6.25C21.2937 6.25 23.125 6.5625 23.125 6.5625V10.5H21.1063C19.1187 10.5 18.5 11.7344 18.5 13V16H22.9375L22.2281 20.625H18.5V31.8063C26.15 30.6063 32 23.9875 32 16Z"
                    fill="#1877F2"/>
                <path
                    d="M22.2282 20.625L22.9375 16H18.5V13C18.5 11.7344 19.1188 10.5 21.1063 10.5H23.125V6.5625C23.125 6.5625 21.2938 6.25 19.5438 6.25C15.8876 6.25 13.5 8.4656 13.5 12.475V16H9.4375V20.625H13.5V31.8063C14.3156 31.9344 15.15 32 16 32C16.85 32 17.6844 31.9344 18.5 31.8063V20.625H22.2282Z"
                    fill="white"/>
              </svg>
            </a>
            <a class="vkAuth" href="#">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M11.0882 0H20.9118C29.8772 0 32 2.12279 32 11.0882V20.9118C32 29.8772 29.8772 32 20.9118 32H11.0882C2.12279 32 0 29.8772 0 20.9118V11.0882C0 2.12279 2.12279 0 11.0882 0Z"
                      fill="#4680C2"/>
                <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M26.1554 10.2137C26.3135 9.71694 26.1554 9.35561 25.4554 9.35561H23.1293C22.5422 9.35561 22.2712 9.67174 22.1131 10.0105C22.1131 10.0105 20.9162 12.9011 19.245 14.7755C18.703 15.3175 18.4546 15.4981 18.1611 15.4981C18.003 15.4981 17.7998 15.3175 17.7998 14.8207V10.1911C17.7998 9.60401 17.6191 9.33301 17.1222 9.33301H13.4638C13.1025 9.33301 12.8767 9.60401 12.8767 9.87501C12.8767 10.4396 13.7122 10.5751 13.8026 12.1559V15.5885C13.8026 16.3337 13.6671 16.4692 13.3735 16.4692C12.5831 16.4692 10.6636 13.556 9.51184 10.2363C9.28598 9.58141 9.06018 9.33301 8.47304 9.33301H6.12438C5.4469 9.33301 5.33398 9.64921 5.33398 9.98794C5.33398 10.5977 6.12438 13.6689 9.01498 17.7339C10.9346 20.5115 13.6671 22.002 16.1286 22.002C17.6191 22.002 17.7998 21.6633 17.7998 21.0987V18.9985C17.7998 18.321 17.9352 18.2081 18.4095 18.2081C18.7482 18.2081 19.358 18.3887 20.7355 19.7211C22.3163 21.3019 22.5873 22.0246 23.468 22.0246H25.7941C26.4716 22.0246 26.7878 21.6859 26.6071 21.0309C26.4038 20.3761 25.636 19.4276 24.6424 18.2984C24.1004 17.6661 23.2874 16.9661 23.039 16.6273C22.7002 16.1757 22.7906 15.995 23.039 15.5885C23.0164 15.5885 25.8618 11.5687 26.1554 10.2137Z"
                      fill="white"/>
              </svg>
            </a>
            <a class="twitterAuth" href="#">
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16Z"
                    fill="#55ACEE"/>
                <path
                    d="M15.5208 13.0046L15.5544 13.5582L14.9948 13.4905C12.9579 13.2306 11.1784 12.3493 9.66756 10.8692L8.92891 10.1347L8.73865 10.6771C8.33575 11.886 8.59316 13.1628 9.43253 14.0215C9.8802 14.496 9.77948 14.5638 9.00725 14.2814C8.73865 14.191 8.50363 14.1232 8.48124 14.1571C8.4029 14.2362 8.6715 15.2643 8.88414 15.6711C9.17513 16.236 9.76828 16.7897 10.4174 17.1173L10.9658 17.3772L10.3167 17.3885C9.68994 17.3885 9.66756 17.3998 9.73471 17.6371C9.95854 18.3715 10.8427 19.1511 11.8276 19.4901L12.5214 19.7273L11.9171 20.0889C11.0218 20.6086 9.96973 20.9024 8.91772 20.925C8.41409 20.9363 8 20.9815 8 21.0154C8 21.1284 9.36538 21.7611 10.16 22.0097C12.5438 22.7441 15.3753 22.4277 17.5017 21.1736C19.0126 20.281 20.5235 18.5071 21.2286 16.7897C21.6091 15.8745 21.9896 14.2023 21.9896 13.4001C21.9896 12.8803 22.0232 12.8125 22.6499 12.1911C23.0192 11.8295 23.3662 11.4341 23.4333 11.3211C23.5452 11.1064 23.534 11.1064 22.9633 11.2985C22.012 11.6375 21.8777 11.5923 22.3477 11.0838C22.6947 10.7223 23.1088 10.0669 23.1088 9.87487C23.1088 9.84097 22.9409 9.89747 22.7506 9.99916C22.5492 10.1121 22.1015 10.2816 21.7658 10.3833L21.1614 10.5754L20.613 10.2025C20.3108 9.99916 19.8856 9.77318 19.6617 9.70539C19.0909 9.54721 18.218 9.56981 17.7032 9.75059C16.3042 10.259 15.4201 11.5697 15.5208 13.0046Z"
                    fill="white"/>
              </svg>
            </a>
          </div> -->
        </div>
        <img class="spray" src="@/img/Spray.svg" alt="#">
      </div>
    </div>
  </transition>
</template>

<script>
import LoginMain from "@/components/modals/login-main";
import LoginPassword from "@/components/modals/login-password";
import LoginRecover from "@/components/modals/login-recover";
import ConfirmRegistration from "@/components/modals/confirm-registration";
import {mapGetters, mapActions, mapMutations} from 'vuex';
import ConfirmRecover from "@/components/modals/confirm-recover";

export default {
  name: "login-modal",
  components: {ConfirmRecover, LoginRecover, LoginPassword, LoginMain, ConfirmRegistration},
  methods: {
    ...mapActions([
        'SHOW_USER_AUTH_MODALS',
    ]),
    ...mapMutations([
        'SET_REG_MODALS'
    ]),
    goToPassAuth() {
      this.SET_REG_MODALS('password-auth');
    },
    goTo(modalType) {
      this.SET_REG_MODALS(modalType);
      // if (modalType === 'register') this.SET_REG_MODALS('main');
    },

    // Когда закрываю модалку - стираю ключ о том, что мы
    // Пытаемся учавствовать в турнире будучи неавторизованным
    closeModal() {
      this.SHOW_USER_AUTH_MODALS({isAuth: false});
      // this.SET_AFTER_AUTH_PARTICIPATE_IN_TOURNAMENT(false);
      localStorage.setItem('afterAuthParticipateInTournament', 'false');
    }
  },
  computed: {
    ...mapGetters([
        'AUTH'
    ]),
  },
}
</script>

<style lang="scss">
.modal-fade-enter-active {
  transition: opacity .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.modal-fade-leave-active {
  transition: opacity .1s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}

.modal-fade-enter, .modal-fade-leave-to {
  opacity: 0;
}
.modal-content {
  .wrapper {
    width: 100%;
    min-height: 310px;
  }
}
</style>