import Vue from 'vue';
import Vuex from 'vuex';

import user from "@/Vuex/modules/user";
import team from "@/Vuex/modules/team";
import tournaments from "@/Vuex/modules/tournaments";
import notifications from "@/Vuex/modules/notifications";
import search from "@/Vuex/modules/search";
import UI_Interactions from "@/Vuex/modules/UI_Interactions";
import modalInviteToTeam from "@/Vuex/modules/modalInviteToTeam";
import modalWarning from "@/Vuex/modules/modalWarning";
import universalModal from "@/Vuex/modules/universalModal";
import groupsOfTournaments from "@/Vuex/modules/groups-of-tournaments";
import threeMainUpcomingEvents from "@/Vuex/modules/threeMainUpcomingEvents";
import staticData from "@/Vuex/modules/staticData";
import matchScoreScreenshots from "@/Vuex/modules/matchScoreScreenshots";
import pages from "@/Vuex/modules/pages";
import disciplines from "@/Vuex/modules/disciplines";
import simpleTournamentModal from "@/Vuex/modules/simpleTournamentModal";


Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        layout: 'default-layout'
    },
    mutations: {
        setLayout(state, payload) {
            state.layout = payload
        }
    },
    actions: {},
    getters: {
        layout(state) {
            return state.layout
        }
    },
    modules: {
        staticData,
        user,
        team,
        tournaments,
        notifications,
        search,
        UI_Interactions,
        modalInviteToTeam,
        modalWarning,
        universalModal,
        groupsOfTournaments,
        threeMainUpcomingEvents,
        matchScoreScreenshots,
        pages,
        simpleTournamentModal,
        disciplines
    }
})

export default store;