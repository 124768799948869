import {API} from "@/Vuex/axios-config";
import {attachmentsGetFromInclude, gameGetFromInclude} from "@/otherJS/include-filtering";

export default {
    state: {
        upcomingEvents: null,
    },

    actions: {
        async SET_THREE_MAIN_UPCOMING_EVENTS_ACTION({commit, getters}) {
            try {
                const response = await API({
                    method: 'get',
                    url: `/tournaments`,
                    params: {
                        'filter[index_of_status]': '0,1',
                        'page[number]': '1',
                        'page[size]': '3',
                        'sort': '-started_at',
                        'include': 'background,game',
                    }
                })

                const upcomingTournaments = response.data;

                upcomingTournaments.data = upcomingTournaments.data.map((item) => {
                    item.background = attachmentsGetFromInclude(item, upcomingTournaments.included, 'background').attributes.url;
                    item.discipline_id = gameGetFromInclude(item, upcomingTournaments.included).id;

                    return item
                });

                commit('SET_THREE_MAIN_UPCOMING_EVENTS_MUTATION', upcomingTournaments)
            } catch (e) {
                console.log('SET_THREE_MAIN_UPCOMING_EVENTS_ACTION', e);
                throw new Error(`SET_THREE_MAIN_UPCOMING_EVENTS_ACTION ${e}`)
            }
        }
    },

    mutations: {
        SET_THREE_MAIN_UPCOMING_EVENTS_MUTATION(state, payload) {
            state.upcomingEvents = payload
        }
    },

    getters: {
        GET_THREE_MAIN_UPCOMING_EVENTS(state) {
            return state.upcomingEvents
        },
    }
}