<template>
  <div class="wrapper">
    <div class="modal-header">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" @click="GO_BACK">
        <path d="M32 32L0 32L0 0H32V32Z" fill="#E2FF31"/>
        <path d="M20 6.66699L10.6667 16.0003L20 25.3337" stroke="#242424" stroke-width="1.5"/>
      </svg>
      Восстановление пароля
    </div>
    <div class="modal-body">
      <div class="label">
        <span class="s1">Телефон или email</span>
      </div>
      <div class="modal-input">
        <input ref="input" type="text" autocomplete="on" placeholder="Например +79856541235" required>
        <div v-if="AUTH.errorText !== ''" class="error" v-html="AUTH.errorText"></div>
      </div>

      <div class="btn__block">
        <button v-if="!AUTH.isRequest" class="filled__yellow" @click="recover">Восстановить пароль</button>
        <spinner v-else />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Spinner from "@/components/UIElements/spinner";
import {isPhoneOrMail} from "@/otherJS/testMailOrPhoneRegExp";

export default {
  name: "login-recover",
  components: {Spinner},
  computed: {
    ...mapGetters([
        'AUTH'
    ])
  },
  methods: {
    ...mapActions([
      'RECOVER_PASSWORD',
      'GO_BACK'
    ]),
    recover() {
      let recoverData = {
        inputValue: this.$refs.input.value.toLowerCase(),
        type: isPhoneOrMail(this.$refs.input.value)
      }
      this.RECOVER_PASSWORD(recoverData);
    },
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 210px;
}
</style>