<template>
  <label class="searchInput">
    <div class="searchInputWrapper">
      <input @focus="this.onFocus" type="search" placeholder="Поиск" @input="onChangeText"
             @blur="onBlur" :value="this.GET_INPUT_SEARCH_TEXT" @keyup.enter="onPressEnter" ref="inputTextRef">

      <svg class="searchLoop" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.33333 12.6667C10.2789 12.6667 12.6667 10.2789 12.6667 7.33333C12.6667 4.38781 10.2789 2 7.33333 2C4.38781 2 2 4.38781 2 7.33333C2 10.2789 4.38781 12.6667 7.33333 12.6667Z"
            stroke="#CACCCF" stroke-linejoin="round"/>
        <path d="M14 13.9996L11.1 11.0996" stroke="#CACCCF" stroke-linejoin="round"/>
      </svg>

      <svg class="searchClose" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"
           @click="onSearchClose">
        <g opacity="0.4">
          <path d="M12.5 12.5L20 20M20 20L27.5 12.5M20 20L12.5 27.5M20 20L27.5 27.5" stroke="white"
                stroke-linejoin="round"/>
        </g>
      </svg>

    </div>

    <div class="searchDropDownContentContainer"
         :class="{searchDropDownContentContainer_active: this.GET_SHOW_DROPDOWN}">
      <search-drop-down/>
    </div>

  </label>
</template>

<script>

import SearchDropDown from "@/components/searchDropDown/searchDropDown";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "InputSearch",
  components: {SearchDropDown},
  data() {
    return {
      timer: null
    }
  },
  methods: {
    ...mapActions([
      'SET_INPUT_SEARCH_TEXT',
      'SET_RESPONSE_SEARCH_DROPDOWN',
      'DELETE_RESPONSE_SEARCH_DROPDOWN',
      'SET_FULL_SEARCH_RESPONSE',
      'DELETE_FULL_SEARCH_RESPONSE',
      'SET_SHOW_DROPDOWN',
      'SET_INPUT_SEARCH_REF',
      'SET_PREVENT_INPUT_BLUR',
      'SET_PREVENT_INPUT_FOCUS',
      'SET_STATUS_MOBILE_NAV',
      'SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN'
    ]),
    onChangeText(e) {
      this.DELETE_RESPONSE_SEARCH_DROPDOWN();
      this.SET_INPUT_SEARCH_TEXT(e.target.value);
      clearTimeout(this.timer);
      this.timer = null;

      if (this.getShowDropDown) {
        this.SET_SHOW_DROPDOWN(true);

        this.timer = setTimeout(() => {
          this.SET_RESPONSE_SEARCH_DROPDOWN(e.target.value);
        }, 1000)
      } else {
        this.SET_SHOW_DROPDOWN(false);
      }
    },

    onSearchClose() {
      this.SET_INPUT_SEARCH_TEXT('');
      this.DELETE_RESPONSE_SEARCH_DROPDOWN();
    },

    onPressEnter() {
      if (!this.GET_INPUT_SEARCH_TEXT) return null
      this.SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN();
      this.SET_FULL_SEARCH_RESPONSE();
      this.SET_STATUS_MOBILE_NAV(false);
    },

    onFocus() {
      if (this.getShowDropDown) {
        this.SET_SHOW_DROPDOWN(true)
      }

      this.SET_PREVENT_INPUT_FOCUS(false);
    },

    onBlur() {
      // Если фокус у кнопки ушел, кроме области дропдауна, то тогда дропдаун исчезает
      if (!this.GET_PREVENT_INPUT_BLUR) {
        this.SET_SHOW_DROPDOWN(false)
      }

      // Ставлю метку, что дропдаун при расфокусе input должен исчезать
      this.SET_PREVENT_INPUT_BLUR(false);
    }

  },
  computed: {
    ...mapGetters([
      'GET_INPUT_SEARCH_TEXT',
      'GET_SEARCH_TAB',
      'GET_SHOW_DROPDOWN',
      'GET_PREVENT_INPUT_BLUR',
      'GET_PREVENT_INPUT_FOCUS'
    ]),
    getShowDropDown() {
      return Boolean(this.GET_INPUT_SEARCH_TEXT && !this.GET_PREVENT_INPUT_FOCUS)
    },

  },

  mounted() {
    this.SET_INPUT_SEARCH_REF(this.$refs.inputTextRef);
  }
}
</script>

<style lang="scss" scoped>
.searchDropDownContentContainer {
  display: none;
  width: 100%;

  &_active {
    display: flex;
  }
}
</style>