/**
 * Сортируем объект с группами в алфавитном порядке
 * */
export const sortGroupsByGrouping = (groupsObjs) => {
    let keys = Object.keys(groupsObjs);
    let result = {};
    const regexp = /[^_][0-9A-Za-z]*$/;

    const slicedKeys = keys.map(key => key.match(regexp)[0]);

    if (isNaN(Number(slicedKeys[0]))) {
        slicedKeys.sort();
    } else {
        slicedKeys.sort((a,b) => a-b);
    }

    slicedKeys.map(el => {
        if (result[el] === undefined) {
            result[el] = [];
        }
        result[el].push(el);
    })

    for (let key in result) {
        result[key] = groupsObjs[`Group_${key}`];
    }

    return result;
}
