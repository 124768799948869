<template>
  <div class="modal-bg" @click="closeModal">
    <div class="modal-body">
      <div class="text" :class="getTextColor">{{ this.configForSimpleTextModal.text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "simple-text-modal",
  props: {
    configForSimpleTextModal: {
      text: String,
      timeoutSeconds: {
        type: Number,
        default: 3
      },
      status: String
    }
  },
  methods: {
    closeModal() {
      this.$emit('closeSimpleModal')
    },
    closeModalTimeout() {
      setTimeout(() => {
        this.closeModal()
      },  this.configForSimpleTextModal.timeoutSeconds * 1000);
    }
  },
  computed: {
    getTextColor() {
      switch (this.configForSimpleTextModal.status) {
        case 'default':
          return 'text_default'
        case 'warning':
          return 'text_warning'
        default:
          return 'text_default'
      }
    }
  },
  mounted() {
    this.closeModalTimeout()
  }
}
</script>

<style lang="scss" scoped>
.modal-bg {
  z-index: 900;
}

.modal-body {
  background-color: #3A3A3A;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 375px;
  height: 250px;
  max-width: unset;

  .text {
    font-weight: 500;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    text-transform: uppercase;

    max-width: 187px;
    user-select: none;

    &_default {
      color: #E2FF31;
    }

    &_warning {
      color: #FF453A;
    }
  }
}
</style>