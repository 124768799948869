<template>
  <div class="searchDropDownContent" @mousedown="onmousedown">
    <div v-if="GET_RESPONSES_STATUS_DROPDOWN">
      <div class="showAllResultsButton" :class="{'showAllResultsButton_preventClick': !getAllResponsesLength}"
           @click="goToSearch">
        <span class="showAllResultsButton__text">{{ getSearchTitle }}</span>
        <div class="showAllResultsButton__arrowRight">
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6.75 3.75L12 9L6.75 14.25" stroke="#B0B0B0" stroke-width="1.5" stroke-linejoin="round"/>
          </svg>
        </div>
      </div>

      <search-players-block/>
      <search-teams-block/>
      <search-tournaments-block/>
    </div>

    <div v-else>
      <spinner/>
    </div>

  </div>
</template>

<script>
import SearchPlayersBlock from "@/components/searchDropDown/searchPlayersBlock/searchPlayersBlock";
import SearchTeamsBlock from "@/components/searchDropDown/searchTeamsBlock/searchTeamsBlock";
import SearchTournamentsBlock from "@/components/searchDropDown/searchTournamentsBlock/searchTournamentsBlock";
import Spinner from "@/components/UIElements/spinner";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "searchDropDown",
  data() {
    return {}
  },
  components: {Spinner, SearchTournamentsBlock, SearchTeamsBlock, SearchPlayersBlock},

  computed: {
    ...mapGetters([
      'GET_RESPONSE_USERS_DROPDOWN',
      'GET_RESPONSES_STATUS_DROPDOWN',
      'GET_RESPONSE_TEAMS_DROPDOWN',
      'GET_INPUT_SEARCH_REF',
      'GET_RESPONSE_TOURNAMENTS_DROPDOWN'
    ]),

    getAllResponsesLength() {
      const usersLength = this.GET_RESPONSE_USERS_DROPDOWN.USERS.length;
      const teamsLength = this.GET_RESPONSE_TEAMS_DROPDOWN.TEAMS.length;
      const tournamentsLength = this.GET_RESPONSE_TOURNAMENTS_DROPDOWN.TOURNAMENTS.length;

      const allLength = usersLength + teamsLength + tournamentsLength;

      return Boolean(allLength);
    },

    getSearchTitle() {
      return this.getAllResponsesLength ? 'Показать все результаты' : 'Результаты не найдены'
    },
  },

  methods: {
    ...mapActions([
      'SET_FULL_SEARCH_RESPONSE',
      'SET_SHOW_DROPDOWN',
      'SET_PREVENT_INPUT_BLUR',
      'SET_PREVENT_INPUT_FOCUS',
      'SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN'
    ]),

    goToSearch() {
      this.SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN();
      this.SET_FULL_SEARCH_RESPONSE();
      this.SET_STATUS_MOBILE_NAV(false);
    },
    onmousedown() {
      // Если был клик по дропдауну, то говорим, чтобы дропдаун не исчезал при расфокусе input
      this.SET_PREVENT_INPUT_BLUR(true);
    },
  },


}
</script>

<style lang="scss" scoped>
.searchDropDownContent {
  position: absolute;
  width: 100%;
  top: 100%;
  left: 0;
  z-index: 3;
  background-color: #272727;
  padding: 20px 9px 24px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;

  display: flex;
  flex-direction: column;

  .showAllResultsButton {
    cursor: pointer;
    width: 100%;
    padding: 7px 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px;
    background: #3A3A3A;

    &__text {
      font-family: 'SF UI Text', serif;
      font-size: 14px;
      line-height: 16.8px;
      letter-spacing: 0.2px;
      color: white;
      font-weight: normal;
    }

    &__arrowRight {
    }

    &:hover {
      background: lighten(#3A3A3A, 10)
    }

    &_preventClick {
      pointer-events: none;

      &:hover {
        background: #3A3A3A;
      }
    }
  }
}
</style>