import {API} from '../axios-config';
import {includeFilteringByTag} from "@/otherJS/include-filtering";


export default {
    state: {
        games: [],
        sponsorGames: []
    },
    mutations: {
        SET_GAMES_TO_STATE: (state, games) => {
            state.games = games;
        },
    },
    actions: {
        async GET_DISCIPLINES({commit}) {
            const res = await API({
                url: `/games?include=logo`,
                method: 'GET',
            });

            let games = res.data.data;
            let logos = includeFilteringByTag(res.data.included, 'logo')

            games.map(game => {
                game.logo = logos.find(logo => logo.id === game.relationships.logo.data.id).attributes.url
            })

            commit('SET_GAMES_TO_STATE', games)
        },
    },
    getters: {
        GAMES(state) {
            return state.games;
        },
    },
}
