<template>
  <div class="notifications" :class="getStyles">
    <div class="notifications_overlay"
         @click="onClose"/>
    <div class="notifications_block">
      <div class="notifications_header">
        <span class="notifications_header_title">Уведомления</span>
        <div class="notifications_header_close" @click="onClose">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
              <path d="M19 5L5 19M5 5L19 19" stroke="white" stroke-width="2"/>
            </g>
          </svg>
        </div>
      </div>
      <div class="notifications_split_line"></div>

      <div class="notifications_body">
        <div v-if="haveNotifications">
          <notification-block v-for="(value, name) in this.GET_NOTIFICATIONS_FILTERED_BY_DATE"
                              :notifications="generateData(value, name)" :key="name"/>
          <div class="body_block_intersectionTrigger"/>
        </div>

        <div v-else-if="haveNotNotifications">
          <notification-have-not-content/>
        </div>

        <div v-else class="spinnerWrapper">
          <spinner/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters} from "vuex";

import NotificationBlock from "@/components/Notifications/components/NotificationBlock/NotificationBlock";
import Spinner from "@/components/UIElements/spinner";
import NotificationHaveNotContent
  from "@/components/Notifications/components/NotificationHaveNotContent/NotificationHaveNotContent";
import {ActiveEcho} from "@/main";

export default {
  name: "Notifications",
  data() {
    return {
      observer: null,
    }
  },
  components: {NotificationHaveNotContent, Spinner, NotificationBlock},
  computed: {
    ...mapGetters([
      'GET_IS_MODAL_NOTIFICATION_OPENED',
      'GET_APPLY_STYLES',
      'GET_NOTIFICATIONS',
      'GET_NOTIFICATIONS_FILTERED_BY_DATE',
      'GET_CAN_SET_MORE_NOTIFICATIONS',
      'GET_USER_IS_AUTHENTICATE'
    ]),

    getShowIntersection() {
      return this.$refs.intersectionNotification
    },

    getStyles() {
      if (this.GET_APPLY_STYLES) {
        return {
          'notifications_active': this.GET_IS_MODAL_NOTIFICATION_OPENED,
          'notifications_inActive': !this.GET_IS_MODAL_NOTIFICATION_OPENED
        }
      }

      return {'': false}
    },

    haveNotifications() {
      if (!this.GET_NOTIFICATIONS) {
        return false
      }

      return this.GET_NOTIFICATIONS.data.length !== 0
    },

    haveNotNotifications() {
      if (!this.GET_NOTIFICATIONS) {
        return false
      }

      return this.GET_NOTIFICATIONS.data.length === 0
    }
  },
  methods: {
    ...mapActions([
      'SET_INACTIVE_MODAL_NOTIFICATIONS',
      'SET_NOTIFICATIONS',
      'SET_MORE_NOTIFICATIONS',
      'SET_WEB_SOCKETS_NEW_NOTIFICATION'
    ]),

    onClose() {
      this.SET_INACTIVE_MODAL_NOTIFICATIONS();
    },
    generateData(value, name) {
      return {
        value,
        name
      }
    }
  },

  watch: {
    GET_IS_MODAL_NOTIFICATION_OPENED: async function () {
      if (this.GET_IS_MODAL_NOTIFICATION_OPENED) {
        await this.SET_NOTIFICATIONS();
      }
    },

    haveNotifications() {
      if (this.haveNotifications) {

        // Добавляем асинхронность, чтобы после обработки DOM в vue мы смогли получить доступ к элементу body_block_intersectionTrigger
        setTimeout(() => {
          this.observer = new IntersectionObserver(async (entries, observer) => {
            for (const entry of entries) {
              if (entry.isIntersecting && this.GET_CAN_SET_MORE_NOTIFICATIONS) {
                await this.SET_MORE_NOTIFICATIONS();
              }
            }
          })

          this.observer.observe(document.querySelector('.body_block_intersectionTrigger'));

        }, 0);
      }
    },

    async GET_USER_IS_AUTHENTICATE() {
      if (this.GET_USER_IS_AUTHENTICATE) {
        await this.SET_NOTIFICATIONS();
      }
    }
  },

  destroyed() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },
}
</script>

<style lang="scss" scoped>
@import "src/scss/vars/mixins";

.notifications {
  transform: translateX(100%);
  position: fixed;
  top: 0;
  left: 0;
  background-color: transparent;
  width: 100vw;
  height: 100vh;
  padding-top: 80px;
  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  z-index: 999;

  &_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 0.45;
    transition: background-color .2s ease;
  }
  &_active {
    .notifications_overlay {
      background-color: #272727;
    }
  }

  &_block {
    position: relative;
    height: fit-content;
    max-width: 375px;
    width: 100%;
    transition: transform 0.45s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateX(100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    padding: 0 16px 0 16px;
    background-color: #272727;
    z-index: 1;
  }

  &_header {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0 16px 0;

    &_title {
      font-family: $blatant-cyrillic, $blatant-latin;
      font-size: 18px;
      line-height: 21.6px;
      letter-spacing: 0.2px;
      color: white;
      text-transform: uppercase;
      font-weight: 400;
    }

    &_close {
      // margin-left: 197px;

      &:hover {
        cursor: pointer;
      }
    }

    &split-line {
      position: absolute;
      bottom: 0;

    }
  }

  &_body {
    margin-right: 16px;
    padding-bottom: 15px;
    width: 100%;
    // height: 100%;
    max-height: calc(100vh - 64px - 59px);
    overflow-y: scroll;

    .spinnerWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    &::-webkit-scrollbar {
      width: 0;
    }
  }

  &_split_line {
    opacity: .4;
    height: 1px;
    width: 100%;
    background-color: white;
  }

  &_inActive {
    transform: translateX(100%);
    transition: transform 0s linear 0.45s;

    .notifications_block {
      transition: transform 0.45s cubic-bezier(0.65, 0, 0.35, 1);
      transform: translateX(100%);
    }
  }

  &_active {
    transform: translateX(0%);

    .notifications_block {
      transition: transform 0.45s cubic-bezier(0.65, 0, 0.35, 1);
      transform: translateX(0);
    }
  }
}

@media all and (max-width: 768px) {
  .notifications {
    &_overlay {
      width: auto;
    }

    &_block {
      max-width: none;
    }
  }
}

</style>
