<template>
  <div class="wrapper">
    <div class="modal-header">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" @click="GO_BACK">
          <path d="M32 32L0 32L0 0H32V32Z" fill="#E2FF31"/>
          <path d="M20 6.66699L10.6667 16.0003L20 25.3337" stroke="#242424" stroke-width="1.5"/>
        </svg>
        Введите код
      </div>
    <div class="modal-body">
        <div class="label">
          <span class="s1">{{ AUTH.authType === 'phone' ? 'Номер' : 'Почта'}} получателя:</span>
          <span class="s2">{{ AUTH.authType === 'phone' ? USER.attributes.phone : USER.attributes.email }}</span>
        </div>
        <div class="modal-input">
          <input
              ref="codeInput"
              @keydown.enter="verifyUser"
              type="text"
              :placeholder=this.placeholder
              required
          >
          <countdown-timer v-if="!AUTH.isRequest" @resend="resendCode" />
          <div v-if="AUTH.errorText !== ''" class="error" v-html="AUTH.errorText"></div>
        </div>

        <div v-if="!AUTH.isRequest" class="btn__block">
          <button
              class="filled__yellow"
              @click="verifyUser"
          >Отправить</button>
        </div>
        <spinner v-else />
      </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import Spinner from "@/components/UIElements/spinner";
import CountdownTimer from "@/components/UIElements/countdown-timer";
import {isPhoneOrMail} from "@/otherJS/testMailOrPhoneRegExp";

export default {
  name: "compare-registration",
  components: {CountdownTimer, Spinner},
  data() {
    return {
      placeholder: ''
    }
  },
  computed: {
    ...mapGetters([
      'USER',
      'AUTH'
    ])
  },
  methods: {
    ...mapActions([
      'CONFIRM_USER_REGISTRATION',
      'GO_BACK',
      'USER_REGISTRATION'
    ]),
    verifyUser() {
      let confirmData = {
        code: this.$refs.codeInput.value
      }
      this.CONFIRM_USER_REGISTRATION(confirmData);
    },
    resendCode() {
      let resendData = this.AUTH.authType === 'phone' ? {inputValue: this.USER.attributes.phone} : {inputValue: this.USER.attributes.email}
      this.USER_REGISTRATION(resendData)
    }
  },
  mounted() {
    this.placeholder = this.AUTH.authType === 'phone' ? 'Введите код из смс' : 'Введите код из email'
  }
}
</script>

<style scoped>

</style>
