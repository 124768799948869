<template>
  <div class="profile-component" :class="{menuActive: isMenuActive}" v-if="isLoaded" @click.stop>
    <media :query="{minWidth: 581}">
      <div class="ava" @click="toggleNavDropdown(!isMenuActive)">
        <img :src="`${USER.logo}`" alt="#">
        <!--        <svg v-if="notification1" width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--          <circle cx="3" cy="3" r="3" fill="#E2FF31"/>-->
        <!--        </svg>-->
      </div>
    </media>

    <media :query="{minWidth: 581}">
      <div class="info" @click="toggleNavDropdown(!isMenuActive)">
        <div class="s1">
          {{ USER.attributes.login === null ? `User` : USER.attributes.login }}
        </div>
        <div class="s2" v-if="USER.attributes.balance">
          <svg width="14" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.49382 2.76628L10.5967 4.86916L8.46616 6.99969L10.5967 9.13024L8.49383 11.2331L6.36328 9.10257L4.23273 11.2331L2.12986 9.13024L4.26041 6.99969L2.12987 4.86915L4.23274 2.76628L6.36328 4.89682L8.49382 2.76628Z"
                fill="#E2FF31"/>
          </svg>
          <span>{{ USER.attributes.balance }}</span>
        </div>
      </div>
    </media>
    <media :query="{maxWidth: 580}">
      <router-link class="ava" :to="{name: 'user', params: {id: USER.id}}" @click="GET_SINGLE_USER(true)">
        <img :src="`${USER.logo}`" alt="#">
        <!--        <svg v-if="notification1" width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">-->
        <!--          <circle cx="3" cy="3" r="3" fill="#E2FF31"/>-->
        <!--        </svg>-->
      </router-link>
    </media>

    <div class="notifications-button" @click="toggleNotificationModal">
      <svg color="bell" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18 8C18 6.4087 17.3679 4.88258 16.2426 3.75736C15.1174 2.63214 13.5913 2 12 2C10.4087 2 8.88258 2.63214 7.75736 3.75736C6.63214 4.88258 6 6.4087 6 8C6 15 3 17 3 17H21C21 17 18 15 18 8Z"
            fill="#B0B0B0" stroke="#B0B0B0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path
            d="M13.7295 21C13.5537 21.3031 13.3014 21.5547 12.9978 21.7295C12.6941 21.9044 12.3499 21.9965 11.9995 21.9965C11.6492 21.9965 11.3049 21.9044 11.0013 21.7295C10.6977 21.5547 10.4453 21.3031 10.2695 21"
            stroke="#B0B0B0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>

      <svg v-if="getNewNotifications" width="4" height="4" class="circle" viewBox="0 0 4 4" fill="none"
           xmlns="http://www.w3.org/2000/svg">
        <circle cx="2" cy="2" r="2" fill="#FF8731"/>
      </svg>
    </div>
    <!--<div class="chat-button">
      <div class="svgWrapper">
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd"
                d="M1 6.39701V18.6002C1 19.8102 1.99 20.8002 3.2 20.8002H20.8C22.01 20.8002 23 19.8102 23 18.6002V6.39701L12.4269 13.7168C12.1701 13.8946 11.8299 13.8946 11.5731 13.7168L1 6.39701ZM22.8717 4.66148C22.5664 3.81197 21.7512 3.2002 20.8 3.2002H3.2C2.24882 3.2002 1.43359 3.81197 1.12835 4.66147L12 12.188L22.8717 4.66148Z"
                fill="#B0B0B0"/>
        </svg>
      </div>
    </div>-->

    <media :query="{minWidth: 581}">
      <div class="menu" @click="toggleNavDropdown(false)">
        <div class="menu-head">
          <router-link class="header__logo" :to="{name: 'user', params: {id: USER.id}}">
            <div class="menu-item">Посмотреть профиль</div>
          </router-link>
          <!--        <div class="menu-item">Подписки</div>-->
        </div>
        <div class="menu-body">
          <div class="link" @click="goToTeam">
            <div class="menu-item">Создать команду</div>
          </div>
          <!--        <div class="menu-item">Пункт меню</div>-->
          <!--        <div class="menu-item">Пункт меню</div>-->
        </div>
        <div class="menu-foot">
          <!--        <div class="menu-item">Поддержка</div>-->
          <div class="menu-item" @click="LOGG_OUT" style="cursor: pointer">Выйти из аккаунта</div>
        </div>
      </div>
    </media>
    <simple-text-modal
        v-if="isSimpleModal"
        :configForSimpleTextModal="modalConfig"
        @closeSimpleModal="isSimpleModal = false"
    />
    <Notifications />
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import Media from 'vue-media';
import SimpleTextModal from "@/components/modals/simple-text-modal";
import Notifications from "@/components/Notifications/Notifications";

export default {
  name: "headerProfile",
  components: {Notifications, SimpleTextModal, Media},
  data() {
    return {
      isLoaded: true,
      notification1: true,
      isMenuActive: false,
      isSimpleModal: false,
      modalConfig: {
        text: 'Вы уже состоите в команде',
        status: 'default',
        timeoutSeconds: 3,
      }
    }
  },
  computed: {
    ...mapGetters([
      'USER',
      'GET_IS_MODAL_NOTIFICATION_OPENED',
      'GET_WEB_SOCKETS_NEW_NOTIFICATIONS'
    ]),
    isUserHasTeam() {
      let team = this.USER.relationships.teams.data.length;
      return !!team;

    },
    getNewNotifications() {
      return this.GET_WEB_SOCKETS_NEW_NOTIFICATIONS
    }
  },
  methods: {
    ...mapActions([
      'GET_SINGLE_USER',
      'LOGG_OUT',
      'SET_TOGGLE_MODAL_NOTIFICATIONS',
      'SET_APPLY_STYLES',
      'SET_INACTIVE_STATUS_MOBILE_NAV',
      'SET_WEB_SOCKETS_NEW_NOTIFICATION'
    ]),

    goToTeam() {
      if (this.isUserHasTeam) {
        this.isSimpleModal = true;
      } else {
        this.$router.push({name: 'team.create'})
      }
    },

    toggleNotificationModal() {
      localStorage.setItem('newNotification', 'false')
      this.SET_WEB_SOCKETS_NEW_NOTIFICATION(false);
      this.SET_INACTIVE_STATUS_MOBILE_NAV();
      this.SET_APPLY_STYLES();
      this.SET_TOGGLE_MODAL_NOTIFICATIONS();
    },

    toggleNavDropdown(flag) {
      this.isMenuActive = flag
    },

    closeNavDropDown() {
      this.isMenuActive = false
    }
  },
  watch: {
    GET_IS_MODAL_NOTIFICATION_OPENED: function () {
      if (this.GET_IS_MODAL_NOTIFICATION_OPENED) {
        if (window.innerWidth <= 768) {
          document.body.classList.add('body_disableOverflow');
        }
      } else document.body.classList.remove('body_disableOverflow');
    },
  },
  mounted() {
    window.addEventListener('click', this.closeNavDropDown, false)
  },
  destroyed() {
    window.removeEventListener('click', this.closeNavDropDown, false)
  }
}
</script>

<style scoped>

</style>
