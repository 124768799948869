const parseNotifications = (notifications, previousData = []) => {

    // Копирую уведомления
    const newNotifications = {...notifications};

    // Возвращаю список уведомлений.
    // Если это приглашение в команду, то оставляю

    // Если уведомление перед началом матча, то проверяю, чтобы партисипанты не были
    // равны null

    // Также здесь уведомления о начале матча

    // Уведомление о том, что противник выставил счёт
    const newNotificationsData = newNotifications.data.filter((note) =>
        note.attributes['type_notification'] === "User.Entries.TeamToUser"
        && note.attributes.status !== 'cancel'

        || note.attributes['type_notification'] === "Match.MatchBeforeStartNotification"

        || note.attributes['type_notification'] === "Match.MatchStartNotification"

        || note.attributes['type_notification'] === "Match.NotificationAboutSetScoreBySecondParticipant"
    )

    // Леха изменил схему получения данных. Записываю в уведомление данные о матче, чтобы потом вытаскивать
    // определенные данные, которые могут понадобиться при отображении уведомления
    newNotificationsData.forEach((note) => {
        if (
            note.attributes['type_notification'] === 'Match.MatchStartNotification'
            || note.attributes['type_notification'] === 'Match.MatchBeforeStartNotification'
            || note.attributes['type_notification'] === 'Match.NotificationAboutSetScoreBySecondParticipant'
        ) {
            note.match = newNotifications.included.find((include) =>
                include.type === 'matches'
                && String(include.id) === String(note.attributes.data.data['match_id']))
        }
    })

    newNotifications.data = [...previousData, ...newNotificationsData]

    return newNotifications

}

export default parseNotifications;