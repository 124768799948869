import {API} from "@/Vuex/axios-config";
import {includeFilteringByTag, includeFilteringByType} from "@/otherJS/include-filtering";
import {ActiveEcho} from "@/main";

export default {
    state: {
        chat: {},
        messages: [],
        onlineUsers: [],
        included: [],
        chatAttachments: [],
        webSocketsNewMessages: JSON.parse(localStorage.getItem('webSocketsNewMessages')),
    },
    mutations: {
        SET_CHAT: (state, data) => {
            state.chat = data;
        },
        SET_MESSAGES: (state, data) => {
            state.messages = data;
        },
        SET_CHAT_ATTACHMENTS: (state, data) => {
            state.chatAttachments = state.chatAttachments.concat(data);
        },
        pushMessage: (state, data) => {
            state.messages.push(data);
        },
        addOnlineUser(state, data) {
            state.onlineUsers.push(data);
        },
        removeOnlineUser(state, data) {
            state.onlineUsers = state.onlineUsers.filter((user) => {
                return user.id !== data.id;
            })
        },
        setOnlineUsers(state, data) {
            state.onlineUsers = data;
        },
        setIncluded(state, data) {
            state.included = data;
        },
        pushIncluded(state, data) {
            state.included.push(data);
        },
        SET_WEB_SOCKETS_NEW_MESSAGES_MUTATION(state, payload) {
            localStorage.setItem('webSocketsNewMessages', String(payload))
            state.webSocketsNewMessages = payload;
        }
    },
    actions: {

        SET_WEB_SOCKETS_NEW_MESSAGES_ACTION({commit}, payload) {
            commit('SET_WEB_SOCKETS_NEW_MESSAGES_MUTATION', payload)
        },

        getChatWithMessages({commit}) {
            API({
                method: 'get',
                url: `/chats/${this.getters.MATCH.data.attributes.chat_id}?include=messages,messages.user,messages.user.logo,messages.attachments`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
            })
                .then(({data}) => {
                    commit('SET_CHAT', data.data)

                    if (data.included !== undefined) {
                        console.log(data.included);
                        console.log(includeFilteringByType(data.included, 'messages'));
                        commit('SET_MESSAGES', includeFilteringByType(data.included, 'messages'));
                        commit('SET_CHAT_ATTACHMENTS', includeFilteringByType(data.included, 'attachments'));
                        commit('setIncluded', data.included)
                    }
                })
        },
        CHAT_UPDATE_NEED_ADMIN({commit}, chat) {
            API({
                method: 'PATCH',
                url: `/chats/${chat.id}`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`
                },
                data: {
                    data: {
                        id: chat.id,
                        type: chat.type,
                        attributes: {
                            need_admin: true
                        },
                    }
                }
            })
        },
        async CREATE_MESSAGE({commit}, data) {
            let files = data.files.map(function (file) {
                return {
                    'id': file.id,
                    'type': file.type,
                }
            });

            let result;
            await API({
                method: 'POST',
                url: `chats/${this.getters.GET_CHAT.id}/createMessage`,
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('userAccessToken')}`,
                    'X-SOCKET-ID': ActiveEcho.socketId()
                },
                data: {
                    data: {
                        "message": data.message
                    },
                    "relationships": {
                        "attachments": {
                            data: files
                        }
                    }
                }
            }).then((response) => {
                commit('pushMessage', response.data.data)
                result = response;
            })

            return result;
        },
    },

    getters: {
        GET_WEB_SOCKETS_NEW_MESSAGES(state) {
            return state.webSocketsNewMessages
        },

        MESSAGES(state) {
            return state.messages
        },
        CHAT_ATTACHMENTS(state) {
            return state.chatAttachments
        },
        GET_CHAT(state) {
            return state.chat;
        },
        onlineUsers(state) {
            return state.onlineUsers;
        },
        chatIncluded(state) {
            return state.included;
        }
    },
}