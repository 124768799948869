import {API} from '../axios-config';
import router from "@/router";

const generateUrlLogosInData = (state, participant) => {

    if (state.dropDownResponses.statusLoaded) {

        const participantObj = {...state.dropDownResponses[participant]};

        const {data, included} = participantObj;

        for (let dataItem of data) {
            for (let includedItem of included) {
                if (
                    String(dataItem.relationships.logo.data.id) === String(includedItem.id)
                    && includedItem.type === 'attachments'
                    && includedItem.attributes.tag === 'logo') {
                    dataItem.logoUrl = includedItem.attributes.url
                }
            }
        }

        return data
    }

    return null
};

const generateCountOfFindersInDropDown = (state, participant) => {
    if (state.dropDownResponses[participant]?.meta) {
        return state.dropDownResponses[participant].meta.page.total
    }

    return null
};

const generateDataForRequest = (fullSearchResponse, values) => {

    const types = ['users', 'teams', 'tournaments'];

    const dataForRequest = {};

    for (const [key, value] of types.entries()) {

        const isHaveIncluded = values[key].data?.included;

        const isNeedLoaded = values[key].data.meta.page['current-page'] !== values[key].data.meta.page['last-page']

        dataForRequest[value] = {
            ...fullSearchResponse[value],
            scrollDataIsLoading: isNeedLoaded,
            meta: {...values[key].data.meta},
            data: [...values[key].data.data],
            included: isHaveIncluded ? [...values[key].data.included] : null
        }
    }

    return dataForRequest;
}

const generateDataForType = (fullSearchResponse, value, type) => {

    const dataForType = {
        ...fullSearchResponse[type],
        meta: {...value.meta},
        data: [...fullSearchResponse[type].data, ...value.data],
        included: type === 'tournaments' ? null : [...fullSearchResponse[type].included, ...value.included]
    }

    return {...fullSearchResponse, [type]: dataForType};
}

/////////////////// Реализовать для каждого скрина свой лоадер (так будет лучше, вроде как)

const defaultFullSearchResponse = {
    users: {
        meta: null,
        data: null,
        included: null,
        page: 1,
        scrollDataIsLoading: true
    },
    teams: {
        meta: null,
        data: null,
        included: null,
        page: 1,
        scrollDataIsLoading: true
    },
    tournaments: {
        meta: null,
        data: null,
        included: null,
        page: 1,
        scrollDataIsLoading: true
    }
}

export default {
    state: {
        dropDownResponses: {
            users: null,
            teams: null,
            tournaments: null,
            statusLoaded: false,
        },

        statusLoading: false,

        inputSearchText: '',
        showDropDown: false,
        memoizedInputSearchText: '',
        inputSearchRef: null,
        searchTab: 'users',

        // State, отвечающий за исчезновение или бездействие дропдауна при расфокусе input
        preventInputBlur: false,
        preventFocusBlur: false,

        fullSearchResponse: {...defaultFullSearchResponse}
    },
    actions: {
        SET_RESPONSE_SEARCH_DROPDOWN({commit}, data) {
            try {
                const responseUsers = API({
                    method: "get",
                    url: `/users?filter[search]=${data}&include=logo&page[number]=1&page[size]=3`
                });
                const responseTeams = API({
                    method: "get",
                    url: `/teams?filter[search]=${data}&include=logo&page[number]=1&page[size]=3`
                });
                const responseTournaments = API({
                    method: "get",
                    url: `/tournaments?filter[search]=${data}&page[number]=1&page[size]=3`
                });

                Promise.all([responseUsers, responseTeams, responseTournaments]).then((values) => {
                    const responses = {
                        users: values[0].data,
                        teams: values[1].data,
                        tournaments: values[2].data,
                        statusLoaded: true
                    }

                    commit('SET_RESPONSE_SEARCH_DROPDOWN', responses);
                })

            } catch (e) {
                console.log('actions SET_RESPONSE_SEARCH', e);
                throw new Error(`actions SET_RESPONSE_SEARCH ${e}`);
            }
        },

        SET_INPUT_SEARCH_REF({commit}, data) {
            this.inputSearchRef = data;
        },

        SET_PREVENT_INPUT_BLUR({commit}, data) {
            commit('SET_PREVENT_INPUT_BLUR', data)
        },

        SET_PREVENT_INPUT_FOCUS({commit}, data) {
            commit('SET_PREVENT_INPUT_FOCUS', data)
        },

        SET_SHOW_DROPDOWN({commit}, data) {
            commit('SET_SHOW_DROPDOWN', data);
        },

        DELETE_RESPONSE_SEARCH_DROPDOWN({commit}) {
            commit('DELETE_RESPONSE_SEARCH_DROPDOWN');
        },

        SET_SEARCH_TAB({commit}, data) {
            commit('SET_SEARCH_TAB', data)
        },

        SET_INPUT_SEARCH_TEXT({commit}, data) {
            commit('SET_INPUT_SEARCH_TEXT', data)
        },

        SET_ALL_SCROLL_LOADING_TRUE({commit}) {
            commit('SET_ALL_SCROLL_LOADING_TRUE')
        },

        SET_FULL_SEARCH_RESPONSE({commit, getters, dispatch}) {

            // Делается запрос на получение пользователей, команд и турниров
            // Есть один общий спиннер и по одному на каждую страницу
            // Общий спинер крутится, когда идет получение данных
            // Частный спинер крутится тогда, когда мы получаем новые данные через скролл (бесконечный скролл)

            // Включаем возможность при клике на инпут открыть дропдаун
            dispatch('SET_PREVENT_INPUT_FOCUS', false);

            // Включаем частные спиннеры
            dispatch('SET_ALL_SCROLL_LOADING_TRUE');

            // Получаем данные по пользователям, командам и турнирам
            const fullSearchResponse = {...getters.GET_FULL_SEARCH_RESPONSE};

            // Включаем общий спинер
            dispatch('SET_ACTIVE_STATUS_LOADING');

            // Если мы находимся на роуте '/search', то остаемся на месте, иначе переходим в этот роут
            const currentHistory = router.history.current.path;
            if (currentHistory !== '/search') router.push({path: '/search'});

            // Удаляем все данные по пользователям/командам/турнирам. Они нам не нужны, это новый запрос
            dispatch('DELETE_FULL_SEARCH_RESPONSE');

            // Получаю текст из инпута
            const filterText = getters.GET_INPUT_SEARCH_TEXT;

            // Если текст не был введен в инпут, но нажали enter, то просто не выводим данные
            if (!filterText) {
                commit('SET_ALL_SCROLL_LOADING_FALSE');
                return
            }

            // Иначе делаем запросы

            // Делаем ссылки на промисы
            try {
                const responseUsers = API({
                    method: 'get',
                    url: `/users?filter[search]=${filterText}&include=logo&page[number]=1&page[size]=50`
                })

                const responseTeams = API({
                    method: 'get',
                    url: `/teams?filter[search]=${filterText}&include=logo&page[number]=1&page[size]=50`
                })

                const responseTournaments = API({
                    method: 'get',
                    url: `/tournaments?filter[search]=${filterText}&page[number]=1&page[size]=50`
                })

                // Собираем массив промисов и итерируя вызываем их
                Promise.all([responseUsers, responseTeams, responseTournaments]).then((values) => {

                    // Возвращаем в хорошо-представленном виде данные
                    const dataForRequest = generateDataForRequest(fullSearchResponse, values);

                    // Мемоизируем значение из инпута (текст из инпута могут удалить,
                    // но этот текст должен отображаться в "Результаты по запросу {query}"
                    if (getters.GET_INPUT_SEARCH_TEXT) {
                        dispatch('SET_MEMOIZED_INPUT_SEARCH_TEXT', getters.GET_INPUT_SEARCH_TEXT);
                    } else {
                        dispatch('SET_MEMOIZED_INPUT_SEARCH_TEXT', getters.GET_MEMOIZED_INPUT_SEARCH_TEXT);
                    }

                    // Заполняем данные
                    commit('SET_FULL_SEARCH_RESPONSE', dataForRequest)
                })

            } catch (e) {
                console.log('search SET_FULL_SEARCH_RESPONSE', e);
                throw new Error(`search SET_FULL_SEARCH_RESPONSE, ${e}`);
            } finally {
                // Делаем неактивным общий спинер
                dispatch('SET_INACTIVE_STATUS_LOADING');
            }
        },

        SET_ADD_SEARCH_TYPE_BY_SCROLLING({commit, getters, dispatch}) {
          const selectedTab = getters.GET_SEARCH_TAB;

            switch (selectedTab) {
                case "users":
                    dispatch('SET_ADD_SEARCH_USERS_BY_SCROLLING')
                    break;
                case "teams":
                    dispatch('SET_ADD_SEARCH_TEAMS_BY_SCROLLING')
                    break;
                case "tournaments":
                    dispatch('SET_ADD_SEARCH_TOURNAMENTS_BY_SCROLLING')
                    break;
                default:
                    break
            }
        },


        SET_SCROLL_LOADING_FALSE({commit}, type) {
            commit('SET_SCROLL_LOADING_FALSE', type)
        },

        async SET_ADD_SEARCH_USERS_BY_SCROLLING({commit, getters, dispatch}) {

            const {users: {page, meta, data}} = getters.GET_FULL_SEARCH_RESPONSE;

            const nextPage = page + 1;
            const filterText = getters.GET_MEMOIZED_INPUT_SEARCH_TEXT;

            if (!meta) {
                console.log('meta не загружена users')
            } else {
                if (meta.page['last-page'] <= page || data.length === 0) {
                    dispatch('SET_SCROLL_LOADING_FALSE', 'users');
                } else {
                    dispatch('SET_PAGE_SEARCH_RESPONSE_USERS');

                    const responseUsers = await API({
                        method: 'get',
                        url: `/users?filter[search]=${filterText}&include=logo&page[number]=${nextPage}&page[size]=50`
                    })

                    const responseData = responseUsers.data;

                    const data = generateDataForType(getters.GET_FULL_SEARCH_RESPONSE, responseData, 'users');

                    commit('SET_FULL_SEARCH_RESPONSE', data);

                }
            }


        },

        async SET_ADD_SEARCH_TEAMS_BY_SCROLLING({commit, getters, dispatch}) {
            const {teams: {page, meta, data}} = getters.GET_FULL_SEARCH_RESPONSE;

            const nextPage = page + 1;
            const filterText = getters.GET_MEMOIZED_INPUT_SEARCH_TEXT;

            if (!meta) {
                console.log('meta не загружена teams')
            } else {
                if (meta.page['last-page'] <= page || data.length === 0) {
                    dispatch('SET_SCROLL_LOADING_FALSE', 'teams');
                } else {
                    dispatch('SET_PAGE_SEARCH_RESPONSE_TEAMS');

                    const responseTeams = await API({
                        method: 'get',
                        url: `/teams?filter[search]=${filterText}&include=logo&page[number]=${nextPage}&page[size]=50`
                    })

                    const responseData = responseTeams.data;

                    const data = generateDataForType(getters.GET_FULL_SEARCH_RESPONSE, responseData, 'teams');

                    commit('SET_FULL_SEARCH_RESPONSE', data)
                }
            }


        },

        async SET_ADD_SEARCH_TOURNAMENTS_BY_SCROLLING({commit, getters, dispatch}) {
            const {tournaments: {page, meta, data}} = getters.GET_FULL_SEARCH_RESPONSE;

            const nextPage = page + 1;
            const filterText = getters.GET_MEMOIZED_INPUT_SEARCH_TEXT;

            if (!meta) {
                console.log('meta не загружена tournaments')
            } else {
                if (meta.page['last-page'] <= page || data.length === 0) {
                    dispatch('SET_SCROLL_LOADING_FALSE', 'tournaments');
                } else {
                    dispatch('SET_PAGE_SEARCH_RESPONSE_TOURNAMENTS');

                    const responseTeams = await API({
                        method: 'get',
                        url: `/tournaments?filter[search]=${filterText}&page[number]=${nextPage}&page[size]=50`
                    })

                    const responseData = responseTeams.data;

                    const data = generateDataForType(getters.GET_FULL_SEARCH_RESPONSE, responseData, 'tournaments');

                    commit('SET_FULL_SEARCH_RESPONSE', data)
                }
            }
        },

        SET_PAGE_SEARCH_RESPONSE_USERS({commit, getters}) {
            const newPageOfUsers = getters.GET_PAGE_OF_USERS_SEARCH + 1;
            commit('SET_PAGE_SEARCH_RESPONSE_USERS', newPageOfUsers);
        },

        SET_PAGE_SEARCH_RESPONSE_TEAMS({commit, getters}) {
            const newPageOfTeams = getters.GET_PAGE_OF_TEAMS_SEARCH + 1;
            commit('SET_PAGE_SEARCH_RESPONSE_TEAMS', newPageOfTeams);
        },

        SET_PAGE_SEARCH_RESPONSE_TOURNAMENTS({commit, getters}) {
            const newPageOfTournaments = getters.GET_PAGE_OF_TOURNAMENTS_SEARCH + 1;
            commit('SET_PAGE_SEARCH_RESPONSE_TOURNAMENTS', newPageOfTournaments);
        },

        SET_ACTIVE_STATUS_LOADING({commit}) {
            commit('SET_ACTIVE_STATUS_LOADING')
        },

        SET_INACTIVE_STATUS_LOADING({commit}) {
            commit('SET_INACTIVE_STATUS_LOADING')
        },

        DELETE_FULL_SEARCH_RESPONSE({commit}) {
            commit('DELETE_FULL_SEARCH_RESPONSE')
        },

        SET_MEMOIZED_INPUT_SEARCH_TEXT({commit}, data) {
            commit('SET_MEMOIZED_INPUT_SEARCH_TEXT', data)
        },

        DELETE_MEMOIZED_INPUT_SEARCH_TEXT({commit}) {
            commit('DELETE_MEMOIZED_INPUT_SEARCH_TEXT')
        },

        SET_PREVIOUS_INPUT_SEARCH_TEXT({commit}, data) {
            commit('SET_PREVIOUS_INPUT_SEARCH_TEXT', data);
        },

        SET_PREVENT_FOCUS_INPUT_AND_CLOSE_DROPDOWN({commit}) {
            commit('SET_PREVENT_INPUT_FOCUS', true);
            commit('SET_SHOW_DROPDOWN', false);
        },

        SET_ENTER_EMPTY_SEARCH({commit}) {
            commit('SET_INACTIVE_STATUS_LOADING');
        }
    },
    mutations: {
        SET_SEARCH_TAB(state, data) {
            state.searchTab = data;
        },

        SET_SCROLL_LOADING_FALSE(state, type) {
            state.fullSearchResponse[type].scrollDataIsLoading = false
        },

        SET_ALL_SCROLL_LOADING_TRUE(state) {
            state.fullSearchResponse.users.scrollDataIsLoading = true;
            state.fullSearchResponse.teams.scrollDataIsLoading = true;
            state.fullSearchResponse.tournaments.scrollDataIsLoading = true;
        },

        SET_ALL_SCROLL_LOADING_FALSE(state) {
            state.fullSearchResponse.users.scrollDataIsLoading = false;
            state.fullSearchResponse.teams.scrollDataIsLoading = false;
            state.fullSearchResponse.tournaments.scrollDataIsLoading = false;
        },




        SET_ACTIVE_STATUS_LOADING(state) {
            state.statusLoading = true
        },

        SET_INACTIVE_STATUS_LOADING(state) {
            state.statusLoading = false
        },

        SET_INPUT_SEARCH_REF(state, data) {
            state.inputSearchRef = data
        },

        SET_SHOW_DROPDOWN(state, data) {
            state.showDropDown = data
        },

        SET_PREVENT_INPUT_BLUR(state, data) {
            state.preventInputBlur = data
        },

        SET_PREVENT_INPUT_FOCUS(state, data) {
            state.preventFocusBlur = data
        },

        SET_PREVIOUS_INPUT_SEARCH_TEXT(state, data) {
            state.previousInputSearchText = data;
        },

        SET_RESPONSE_SEARCH_DROPDOWN(state, data) {
            state.dropDownResponses = data;
        },

        SET_FULL_SEARCH_RESPONSE(state, data) {
            state.fullSearchResponse = data;
        },

        DELETE_FULL_SEARCH_RESPONSE(state) {
            state.fullSearchResponse = {...defaultFullSearchResponse}
        },

        DELETE_RESPONSE_SEARCH_DROPDOWN(state) {
            state.dropDownResponses = {
                users: null,
                teams: null,
                tournaments: null,
                statusLoaded: false
            }
        },

        SET_INPUT_SEARCH_TEXT(state, data) {
            state.inputSearchText = data;
        },

        SET_MEMOIZED_INPUT_SEARCH_TEXT(state, data) {
            state.memoizedInputSearchText = data;
        },

        DELETE_MEMOIZED_INPUT_SEARCH_TEXT(state) {
            state.memoizedInputSearchText = '';
        },

        SET_PAGE_SEARCH_RESPONSE_USERS(state, data) {
            state.fullSearchResponse.users.page = data;
        },
        SET_PAGE_SEARCH_RESPONSE_TEAMS(state, data) {
            state.fullSearchResponse.teams.page = data;
        },
        SET_PAGE_SEARCH_RESPONSE_TOURNAMENTS(state, data) {
            state.fullSearchResponse.tournaments.page = data;
        },
    },
    getters: {
        GET_PAGE_OF_USERS_SEARCH(state) {
            return state.fullSearchResponse.users.page
        },

        GET_PAGE_OF_TEAMS_SEARCH(state) {
            return state.fullSearchResponse.teams.page
        },

        GET_PAGE_OF_TOURNAMENTS_SEARCH(state) {
            return state.fullSearchResponse.tournaments.page
        },

        GET_SEARCH_TAB(state) {
            return state.searchTab
        },

        GET_STATUS_LOADING(state) {
            return state.statusLoading
        },

        GET_INPUT_SEARCH_REF(state) {
            return state.inputSearchRef
        },

        GET_SHOW_DROPDOWN(state) {
            return state.showDropDown
        },

        GET_PREVENT_INPUT_BLUR(state) {
            return state.preventInputBlur
        },

        GET_PREVENT_INPUT_FOCUS(state) {
            return state.preventFocusBlur
        },

        GET_FULL_SEARCH_RESPONSE(state) {
            return state.fullSearchResponse
        },

        GET_INPUT_SEARCH_TEXT(state) {
            return state.inputSearchText
        },

        GET_RESPONSES_STATUS_DROPDOWN(state) {
            return state.dropDownResponses.statusLoaded;
        },

        GET_RESPONSE_USERS_DROPDOWN(state) {
            return {
                USERS: generateUrlLogosInData(state, 'users'),
                COUNT: generateCountOfFindersInDropDown(state, 'users'),
            }
        },

        GET_RESPONSE_TEAMS_DROPDOWN(state) {
            return {
                TEAMS: generateUrlLogosInData(state, 'teams'),
                COUNT: generateCountOfFindersInDropDown(state, 'teams'),
            }
        },

        GET_RESPONSE_TOURNAMENTS_DROPDOWN(state) {
            return {
                TOURNAMENTS: state.dropDownResponses.tournaments?.data,
                COUNT: generateCountOfFindersInDropDown(state, 'tournaments'),
            }
        },

        GET_FULL_RESPONSE_TYPE(state) {
            // Смотрю какой сейчас таб активирован
            const type = state.searchTab;

            // Делаю проверку на пустоту. Если элементов нет, то выведется "(не найдены)"
            if (!state.fullSearchResponse[type].data || state.fullSearchResponse[type].data.length === 0) return [];

            // беру список всех найденных юзеров/турниров/команд
            const dataData = [...state.fullSearchResponse[type].data];

            // Делаю проверку на турнир. В нем нам не нужны included, поэтому просто возвращаем список турниров
            if (type === 'tournaments') return dataData;

            // Делаю проверку на команды. В командах мы должны указать сколько игроков играет
            if (type === 'teams') {
                for (let itemData of dataData) {
                    itemData.countOfPlayers = itemData.relationships.users.meta.roles.length;
                }
            }


            // Собираю included, чтобы получить ссылку на аватарку
            const includedData = [...state.fullSearchResponse[type].included];


            // собираем
            for (let itemData of dataData) {
                for (let includeData of includedData) {
                    if (String(itemData.relationships.logo.data.id) === String(includeData.id)
                        && includeData.type === 'attachments'
                        && includeData.attributes.tag === 'logo') {
                        itemData.logoUrl = includeData.attributes.url
                    }
                }
            }

            // Возвращаем
            return dataData;
        },

        GET_MEMOIZED_INPUT_SEARCH_TEXT(state) {
            return state.memoizedInputSearchText;
        },
    }
}