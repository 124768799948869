import Vue from 'vue';
import App from './App';
import router from './router';
import store from './Vuex/store';
import './otherJS/filters';
import Vuelidate from 'vuelidate';
import Scrollspy from 'vue2-scrollspy';
import VueCountdownTimer from 'vuejs-countdown-timer';
import VueAwesomeSwiper from 'vue-awesome-swiper';

import container from '@/layouts/container';
import nocontainer from "@/layouts/nocontainer";
import profile from "@/views/profile";
import Echo from "laravel-echo";
import {BACKEND_URL, PUSHER_APP_CLUSTER, PUSHER_APP_KEY} from "@/config";
import Unavailable from "@/components/Unavailable";
import {siteIsClose} from "@/otherJS/utils";

window.Pusher = require('pusher-js');


//region Echo
let echoOption = {
    broadcaster: 'pusher',
    key: PUSHER_APP_KEY,
    cluster: PUSHER_APP_CLUSTER,
    forceTLC: true
};
const accessToken = localStorage.getItem('userAccessToken');
if (accessToken) {
    echoOption = Object.assign(echoOption, {
            authEndpoint: `${BACKEND_URL}/broadcasting/auth`,
            auth: {
                headers: {
                    Authorization: 'Bearer ' + accessToken
                },
            },
        }
    );
}

export const ActiveEcho = new Echo(echoOption);
//endregion


Vue.component('container-layout', container);
Vue.component('nocontainer-layout', nocontainer);
Vue.component('profile-layout', profile);

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(Scrollspy);
Vue.use(VueCountdownTimer);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */);


Vue.directive('click-outside', {
    bind: function (el, binding, vnode) {
        el.clickOutsideEvent = function (event) {
            // here I check that click was outside the el and his children
            if (!(el === event.target || el.contains(event.target))) {
                // and if it did, call method provided in attribute value
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
    },
    unbind: function (el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
    },
});

if (siteIsClose()) {
    //Пошел нахуй

    new Vue(Unavailable).$mount('#app');

} else {
    //Приложение работает

    new Vue({
        router,
        store,
        render: h => h(App)
    }).$mount('#app');

}
