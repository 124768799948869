<template>
  <header class="header">
    <div class="header__bg"></div>
    <div class="container">
      <div class="header__wrapper">
        <router-link class="header__logo" active-class="" exact-active-class="" to="/">
          <svg width="128" height="28" viewBox="0 0 128 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0)">
              <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M24.4708 11.4693H13.9107C6.71789 11.4499 6.33156 10.0128 6.33156 8.30093C6.33156 6.57292 8.41576 4.89973 12.6361 4.89973C12.7308 4.89973 23.1862 4.90137 23.281 4.90301C27.4035 4.98861 31.428 6.92035 31.428 6.92035L33.1363 2.38278C33.1363 2.38278 29.4271 0.0129518 23.4065 0.0129518C23.3088 0.0129518 12.8475 0.0129467 12.7513 0.0145875C6.18321 0.0986773 1.60469 3.02624 1.60469 8.98636C1.60469 14.0073 5.0982 16.3447 11.4946 16.3447H22.0417C28.2826 16.3674 29.7895 17.5701 29.7895 19.7798C29.7895 21.9622 27.3647 23.041 23.254 23.0887C23.1593 23.0901 12.701 23.0901 12.6032 23.0901C8.31629 23.0901 2.05147 20.0054 2.05147 20.0054L0.00156403 24.4847C0.00156403 24.4847 6.10042 27.8798 12.6937 27.9987C12.8091 28.0001 23.2882 28.0015 23.4051 28.0015C30.1301 28.0015 34.4659 24.6734 34.4659 19.8491C34.4659 15.0248 30.9622 11.4693 24.4708 11.4693ZM92.3016 14.4146V18.9861H99.0439C97.7654 21.6532 95.2043 23.1974 90.9981 23.2201H50.9297C44.4757 23.2201 41.7708 19.7523 41.7708 13.9991C41.7708 8.24583 44.4754 4.78009 50.9297 4.78009H90.9902C97.9835 4.80593 100.684 7.85655 100.684 7.85655L104.191 4.811C104.191 4.811 100.24 0.0374255 91.0072 -0.00195312C90.9718 -0.000312348 50.9684 -0.000309076 50.9313 -0.000309076C42.2566 -0.000309076 36.63 6.26691 36.63 14.001C36.63 21.7351 42.2566 28.0023 50.9313 28.0023H90.9915C94.6931 27.9831 97.8311 26.8168 100.209 24.8875V27.5839H105.2V14.4146H92.3016ZM113.9 0.404411H108.908V27.5654H128V22.9931H113.9V0.404411Z"
                    fill="#E2FF31"/>
              <path
                  d="M62.488 6.59341L66.0188 10.2674L62.4415 13.9897L66.0189 17.712L62.488 21.386L58.9107 17.6637L55.3334 21.386L51.8026 17.712L55.3799 13.9897L51.8026 10.2674L55.3334 6.59341L58.9107 10.3157L62.488 6.59341Z"
                  fill="white"/>
              <path
                  d="M81.8044 6.59341L85.3352 10.2674L81.7579 13.9897L85.3352 17.712L81.8044 21.386L78.2271 17.6637L74.6498 21.386L71.119 17.712L74.6963 13.9897L71.119 10.2674L74.6498 6.59341L78.2271 10.3157L81.8044 6.59341Z"
                  fill="white"/>
            </g>
            <defs>
              <clipPath id="clip0">
                <rect width="128" height="28" fill="white"/>
              </clipPath>
            </defs>
          </svg>
        </router-link>
        <nav class="header__nav">
          <ul>
            <li v-for="link in navItems" :key="link.path">
              <router-link :to="{name: link.path}" class="header__link" exact-active-class="active">{{ link.label }}</router-link>
            </li>
          </ul>
        </nav>
        <search-input/>
        <div class="profile-area">
          <header-profile v-if="AUTH.isAuthenticate"></header-profile>
          <div class="login-buttons" v-else>
            <button class="filled__yellow" @click.stop="SHOW_USER_AUTH_MODALS({isAuth: true, modal: 'password-auth'})">Войти</button>
          </div>
        </div>
        <media :query="{maxWidth: 1100}">
          <div class="more" @click="toggleStatusMobileNav">
            <div class="more_btn more_btn__mobile" :class="{active: this.GET_STATUS_MOBILE_NAV}">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </media>
      </div>
    </div>

    <media :query="{maxWidth: 1100}">
      <div class="mobile_nav" :class="{active: this.GET_STATUS_MOBILE_NAV}">
        <div class="container">
          <search-input/>
          <nav>
            <ul>
              <li @click="closeMobileNav" v-for="link in navItems" :key="link.path">
                <router-link :to="{name: link.path}" class="header__link" exact-active-class="active">{{ link.label }}</router-link>
              </li>
            </ul>
          </nav>
          <media :query="{maxWidth: 580}">
            <div class="divider" v-if="AUTH.isAuthenticate"></div>
          </media>
          <media :query="{maxWidth: 580}">
            <nav v-if="AUTH.isAuthenticate">
              <ul>
                <li @click="closeMobileNav">
                  <router-link :to="{name: 'user', params: {id: USER.id}}" class="header__link"
                               exact-active-class="active">Профиль
                  </router-link>
                </li>
                <li @click="closeMobileNav">
                  <router-link to="/teams/create" class="header__link" exact-active-class="active">Создать команду
                  </router-link>
                </li>
                <li @click="closeMobileNav">
                  <div class="header__link" @click="LOGG_OUT">Выйти из аккаунта</div>
                </li>
              </ul>
            </nav>
          </media>
        </div>
      </div>
    </media>

    <login-modal
        v-if="AUTH.isAuthOpen"
    />
  </header>
</template>

<script>
import searchInput from "@/components/UIElements/InputSearch";
import HeaderProfile from "@/components/UIElements/headerProfile";
import Media from 'vue-media';
import LoginModal from "@/components/modals/login-modal";
import {mapGetters, mapActions} from 'vuex';

export default {
  name: "headerMain",
  components: {
    HeaderProfile,
    searchInput,
    Media,
    LoginModal
  },
  data() {
    return {
      navItems: [
        {path: 'home', label: 'Главная'},
        {path: 'allTournaments', label: 'Турниры'},
        {path: 'FAQ', label: 'FAQ'},
        {path: 'partners', label: 'Партнеры'},
        {path: 'requisites', label: 'Реквизиты'},
      ]
    }
  },
  computed: {
    ...mapGetters([
      'AUTH',
      'GET_STATUS_MOBILE_NAV'
    ]),
  },
  methods: {
    ...mapActions([
      'SHOW_USER_AUTH_MODALS',
      'USER',
      'GET_SINGLE_USER',
      'LOGG_OUT',
      'SET_STATUS_MOBILE_NAV',
      'TOGGLE_STATUS_MOBILE_NAV',
      'SET_INACTIVE_MODAL_NOTIFICATIONS'
    ]),
    closeMobileNav() {
      this.SET_STATUS_MOBILE_NAV(false)
    },
    toggleStatusMobileNav() {
      this.SET_INACTIVE_MODAL_NOTIFICATIONS()
      this.TOGGLE_STATUS_MOBILE_NAV()
    }
  },
  watch: {
    GET_STATUS_MOBILE_NAV: function () {
      // document.documentElement.style.overflow = this.GET_STATUS_MOBILE_NAV ? 'hidden' : 'auto';

      if (this.GET_STATUS_MOBILE_NAV) {
        document.body.classList.add('body_disableOverflow');
      } else {
        document.body.classList.remove('body_disableOverflow');
      }
    }
  },
  mounted() {
    /*let showOrHideNav = new HideNavToMore('.header__nav .more', '.header__nav', '.header__nav .hidden__els', '.header__nav li', 1)
    showOrHideNav.start();*/
  }
}
</script>

<style scoped lang="scss">
.filled__yellow {
  width: 200px;
  margin-right: 16px;
  @media (max-width: 525px) {
    width: 35vw;
  }
}
</style>
