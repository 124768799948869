// Функция, которая запоминает, нужно ли показывать предупреждающую модалку после перезагрузки страницы
import {vkOnLoad} from "@/otherJS/socialsRegistration/vk";

const showModalAfterAuthFromTournament = () => {
    if (JSON.parse(sessionStorage.getItem('reload'))) {
        sessionStorage.setItem('startToReload', 'true')
    }
}


// Коллбэк, который будет осуществляться после загрузки страницы
const doAllThings = () => {
    showModalAfterAuthFromTournament();
    // vkOnLoad();
}

const onLoadListener = () => {
    window.addEventListener('load', doAllThings)
}

const destroyLoadListener = () => {
    window.removeEventListener('load', doAllThings)
}

export {onLoadListener, destroyLoadListener};