import Vue from 'vue'
import Router from 'vue-router'
import store from './Vuex/store'


Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    linkExactActiveClass: "active",
    routes: [
        {
            path: '/',
            name: 'home',
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/Home')
        },
        {
            path: '/tournaments',
            name: 'allTournaments',
            props: true,
            component: () => import('@/views/tournaments')
        },
        {
            path: '/matches/:id',
            name: 'SingleMatch',
            meta: {layout: 'nocontainer'},
            component: () => import('@/components/tournaments/singleTournament/singleMatch/SingleMatch'),
            redirect: {
                name: 'match.info'
            },
            children: [
                {
                    path: 'info',
                    name: 'match.info',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "matchInfo" */'@/components/tournaments/singleTournament/singleMatch/SingleMatchInfo')
                },
                {
                    path: 'feed',
                    name: 'match.feed',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "matchFeed" */'@/components/tournaments/singleTournament/singleMatch/SingleMatchFeed')

                },
                {
                    path: 'stream',
                    name: 'match.stream',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "matchFeed" */'@/components/tournaments/singleTournament/singleMatch/SingleMatchStream')

                }
            ]
        },
        {
            path: '/tournaments/:id',
            name: 'tournaments_id',
            meta: {layout: 'nocontainer'},
            props: true,
            component: () => import(/*webpackChunkName: "tournamentPage" */'@/views/tournamentPage'),
            redirect: {
                name: 'tournaments.info'
            },
            children: [
                {
                    path: 'info',
                    name: 'tournaments.info',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "tournamentInfo" */'@/components/tournaments/singleTournament/tournamentInfo')
                },
                {
                    path: 'teams',
                    name: 'tournaments.teams',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "tournamentTeams" */'@/components/tournaments/singleTournament/tournamentTeams')
                },
                {
                    path: 'brackets',
                    name: 'tournaments.brackets',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "tournamentBrackets" */'@/components/tournaments/singleTournament/brackets/tournamentBrackets'),
                    redirect: to => {
                        if (store.getters.TOURNAMENT.attributes.group_type !== null) return {name: 'groups'}
                        if (store.getters.TOURNAMENT.attributes.group_type === null) return {name: 'playoff'}
                    },
                    children: [
                        {
                            path: 'groups',
                            name: 'groups',
                            props: true,
                            meta: {layout: 'nocontainer'},
                            component: () => import(/*webpackChunkName: "groupstageList" */'@/components/tournaments/singleTournament/brackets/groupstage/groupstageList')
                        },
                        {
                            path: 'playoff',
                            name: 'playoff',
                            props: true,
                            meta: {layout: 'nocontainer'},
                            component: () => import(/*webpackChunkName: "playOff" */'@/components/tournaments/singleTournament/brackets/play-off/play-off')
                        },
                    ]
                },
                {
                    path: 'matches',
                    name: 'tournaments.matches',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "tournamentMatches" */'@/components/tournaments/singleTournament/matches/tournamentMatches'),
                },
                {
                    path: 'rules',
                    name: 'tournaments.rules',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "tournamentRules" */'@/components/tournaments/singleTournament/tournamentRules'),
                },
                {
                    path: 'videos',
                    name: 'tournaments.videos',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "tournamentVideos" */'@/components/tournaments/singleTournament/tournamentVideos'),
                },
                {
                    path: 'news',
                    name: 'tournaments.news',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "tournamentNews" */'@/components/tournaments/singleTournament/tournamentNews'),
                },
                {
                    path: 'statistics',
                    name: 'tournaments.statistics',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/*webpackChunkName: "tournamentStatistics" */'@/components/tournaments/singleTournament/tournamentStatistics'),
                },
                {
                    path: 'winners',
                    name: 'tournaments.winners',
                    props: true,
                    meta: {layout: 'nocontainer'},
                    component: () => import(/* webpackChunkName: "tournamentWinners" */'@/components/tournaments/singleTournament/winners/tournamentWinners'),
                }
            ]
        },
        {
            path: '/profile',
            name: 'profile',
            meta: {layout: 'nocontainer', requiresAuth: true},
            component: () => import('@/views/profile'),
            children: [
                {
                    path: '',
                    name: 'user',
                    meta: {layout: 'nocontainer'},
                    component: () => import('@/components/profile/content/profileInfo')
                },
                {
                    path: 'info',
                    name: 'user.info',
                    meta: {layout: 'nocontainer'},
                    component: () => import('@/components/profile/content/profileInfo')
                },
                {
                    path: 'security',
                    name: 'user.security',
                    meta: {layout: 'nocontainer'},
                    component: () => import('@/components/profile/content/profileSecurity')
                },
                {
                    path: 'links',
                    name: 'user.links',
                    meta: {layout: 'nocontainer'},
                    component: () => import('@/components/profile/content/profileLinks')
                },
                {
                    path: 'notifications',
                    name: 'user.notifications',
                    meta: {layout: 'nocontainer'},
                    component: () => import('@/components/profile/content/profileNotifications')
                },
                {
                    path: 'friends',
                    name: 'user.friends',
                    meta: {layout: 'nocontainer'},
                    component: () => import('@/components/profile/content/profileFriends')
                }
            ]
        },
        {
            path: '/teams/create',
            name: 'team.create',
            meta: {layout: 'nocontainer', requiresAuth: true},
            component: () => import('@/views/TeamProfileCreate'),
        },

        {
            path: '/teams/:id',
            name: 'team.name',
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/TeamProfileInformation')
        },
        {
            path: '/users/:id',
            name: 'another-profile',
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/another-profile'),
        },
        {
            path: '/main',
            name: 'home-page',
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/Home')
        },
        {
            path: '/search',
            name: 'search-page',
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/Search')
        },
        {
            path: '/search',
            name: 'search-page',
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/Search')
        },
        {
            path: '/events-group/:id',
            name: 'events-group',
            props: true,
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/eventGroups')
        },
        {
            path: '/FAQ',
            name: 'FAQ',
            props: true,
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/FAQ')
        },
        {
            path: '/partners',
            name: 'partners',
            props: true,
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/partners')
        },
        {
            path: '/requisites',
            name: 'requisites',
            props: true,
            meta: {layout: 'nocontainer'},
            component: () => import('@/views/requisites')
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
})

router.beforeResolve((to, from, next) => {
    /**
     * Редирект, если не авторизованные пользователь идет по авторизованным маршрутам
     * */
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.AUTH.isAuthenticate === false) {
            next({
                name: 'home',
            })
        } else {
            next()
        }
    } else {
        next()
    }

    /**
     * Редирект на профиль, если мы заходим на свой профиль по ссылке типа: '/users/:id'
     * */
    if (to.matched.some(record => record.name === 'another-profile')) {
        if (Number(to.params.id) === Number(store.getters.USER.id)) {
            next({
                name: 'user'
            })
        }
    }
})


export default router;
